<template>
  <div class="editor-audio">
    <p class="editor-text">Replace asset</p>
    <DropZone
      class="uploader"
      :progress-total="total"
      :progress-loaded="loaded"
      @file-drop="handleFileDrop"
      @clear-changes="clearAllChanges"
      audioType
      data-test="audio-editor-dropzone"
    ></DropZone>
  </div>
</template>

<script>
import DropZone from "@/components/DropZone";
import { useUploadToS3 } from "@/helper/uploadToS3Composable.js";
import { mapActions } from "vuex";

export default {
  name: "AudioEditor",
  components: {
    DropZone,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: null,
    total: null,
  }),
  methods: {
    ...mapActions("builder", ["updateNodeValue", "undoNodeChanges"]),
    handleFileDrop(file) {
      const { loaded, total, request } = useUploadToS3(
        file,
        process.env.VUE_APP_CUSTOM_CONTENT_URL,
        process.env.VUE_APP_MEDIA_UPLOAD_ENDPOINT
      );
      this.loaded = loaded;
      this.total = total;
      request.then((link) => {
        this.mediaLink = link;
        this.updateNodeValue({
          node: this.node,
          data: { val: link },
        });
        this.$emit("preview");
      });
    },
    clearAllChanges() {
      this.loaded = this.total = null;
      this.undoNodeChanges();
      this.$emit("preview");
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-audio {
  margin-top: 27px;

  .editor-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #07354a;
  }
  .uploader {
    margin-top: 24px;
  }
}
</style>

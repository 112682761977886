<template>
  <div class="center">
    <h1>Hello!</h1>
    <h2>Please provide you scene id:</h2>
    <IdmInput class="i" @inputChange="sceneId = $event" @keyup.enter="goToScene($event)" data-test="scene-id-input"> </IdmInput>
    <IdmButton v-show="sceneId" class="b" :text="`go to scene: ${sceneId}`" btnType="secondary" @click="goToScene" data-test="go-to-scene-button"> </IdmButton>

    <div v-if="scenesInLs.length" style="margin-top:20px;padding-top:10px;border-top: 1px solid #aaa;min-width: 300px;" data-test="last-viewed-scenes-container">
      last viewed scenes:
      <IdmButton
        v-for="s in scenesInLs"
        :text="`go to scene ${s.id} (${s.name})`"
        btnType="outline"
        @click="goToScene(s.id)"
        style="margin-top:5px"
      />
    </div>


  </div>
</template>

<script>


export default {
  name: 'homePage',

  data: () => ({
    sceneId: '',
    scenesInLs: [],
  }),

  methods: {
    goToScene(id) {
      window.location = `/${id || this.sceneId}`
    },
  },

  beforeMount() {
    const originalPath = window.localStorage.auth_originalPathname;
    if (originalPath && originalPath !== '/') {
      console.info('redirect: ', originalPath);
      this.$router.push({path: originalPath})
    }
  },
  mounted(){ //temporary code - to display the last open scenes on the main page (todo)
    let scenesInLs = window.localStorage.lastViewedScenes
    if(scenesInLs && scenesInLs.length){
      this.scenesInLs = JSON.parse(scenesInLs)
    }
  }
}
</script>

<style scoped>
  .center{
    display: flex;
    align-self: center;
    height: 100%;
    align-items: center;
    padding-bottom: 300px;
    flex-direction: column;
    justify-content: center;
  }
  .i, .b{
    margin-top:20px
  }
</style>
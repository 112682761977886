import _ from 'lodash'
import { reactive } from 'vue'
import getMediaType from "@/helper/getMediaType";

const htmlDecode = (input) => {
  //decode HTML entities (for example, &#43;)
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export default (scene,placeholders_original,frames)=>{
  console.log('SCENE', scene,placeholders_original,frames);
  const name = _.get(scene, 'display_name') || _.get(scene, 'scene_name')
  const display_name = htmlDecode(name)

  const placeholders = placeholders_original.map(p => {
    const key = p.button_stats.replace(display_name+'_', '')
    return {key, ...p, points:{}}
  })

  const nodeMap = (node, media_type) => {
    let type = node.media_type || media_type || null

    const isColor = (color) => {
      const style = new Option().style
      style.color = color
      return style.color !== ''
    }

    if (media_type === 'media') {
      type = (isColor(node.val) || isColor(node.default)) ? 'color' : 
      getMediaType(node.val)
    }

    return {
      ...node,
      media_type: type,
      placeholder: placeholders.find(p=>p.key==node.key) || null,
      name: node.key,
      tags: _.get(node, 'tags', []),
      description: _.get(node, 'description', ''),
      is_trackmatte: _.get(node, 'is_trackmatte', false),
      branding: false,
    }
  }

  const nodes = _.concat(
    scene.media.map(node=>{return nodeMap(node, 'media')}),
    scene.text.map(node=>{return nodeMap(node, 'text')}),
    scene.audio.map(node=>{return nodeMap(node, 'audio')}),
  )

  const fps = scene.fps || 25

  frames.forEach(f => {
    f.placeholders.forEach(p => {
      const node = nodes.find(n=>n.placeholder && n.placeholder.id == p.data_id)
      if(!node){
        return console.log('placeholder does not belong to any node!', f.frame, p);
      }
      node.placeholder.points[f.frame] = p.points

      if (!node.hasOwnProperty('custom_start_time')) {
        node.custom_start_time = node.hasOwnProperty('start_time') ? Math.min(node.start_time, f.frame / fps) : f.frame / fps
      }
    })
  })

  return reactive({
    id: scene.scene_id,
    last_modified: scene.last_modified,
    owner: scene.owner,
    fps: fps,
    width: scene.scene_width,
    height: scene.scene_height,
    duration:{
      frames: scene.duration_in_frames || Math.ceil(scene.duration_in_seconds * fps),
      seconds: scene.duration_in_seconds,
    },
    max_duration_in_seconds: scene.max_duration_in_seconds,
    display_name,
    display_name_original: scene.display_name,
    edited: false,
    nodes,
    description: _.get(scene, 'description', ''),
    tags: _.get(scene, 'tags', []),
  })
}
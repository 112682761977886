<template>
  <div class="dropdown-action" ref="popupBtn">
    <IdmButton
      class="dropdown-trigger"
      :btnType="'primary'"
      :text="'Save'"
      @mouseenter="mouseEnterEvent"
      @mouseleave="mouseLeaveEvent"
      data-test="scene-save-dropdown-trigger-button"
    >
      <IdmIcon name="arrow-down" />
    </IdmButton>

    <div v-if="showSuccessMsg" class="success-msg">
      <span class="text">Your scene was saved successfully</span>
      <img src="@/assets/icons/smile.svg" />
    </div>

    <div
      v-if="scene && isHover"
      ref="popupEl"
      class="dropdown-content save"
      @mouseleave="mouseLeaveEvent"
    >
      <IdmAccordion :items="menuItems">
        <template v-slot:save>
          <div class="accordion-content">
            <p>Are you sure you wish to save the scene with all the changes?</p>
            <p class="mt-10">This will overwrite the existing scene.</p>
            <div class="buttons">
              <IdmButton
                :class="'cancel-btn'"
                :btnType="'secondary'"
                :text="'Cancel'"
                @click.stop="isHover = false"
                data-test="scene-save-cancel-button"
              ></IdmButton>
              <IdmButton
                class="save-btn"
                :btnType="'primary'"
                :text="saving ? '' : 'Save'"
                @click.stop="save"
                data-test="scene-save-button"
              >
                <IdmLoader v-if="saving" style="width: 20px; height: 20px" />
              </IdmButton>
            </div>
          </div>
        </template>

        <template v-slot:save-as>
          <div class="accordion-content">
            <p>Save this scene with all the changes as a new scene:</p>
            <div class="inputs">
              <IdmInput
                :value="sceneName"
                @click.stop
                @inputChange="sceneNameChanged"
                :style="'background-color: #fff; border-radius: 8px; border-color: transparent;'"
                data-test="scene-save-as-scene-name-input"
              />
              <div class="libraries">
                <IdmDropdown
                  v-if="libraries"
                  :items="libraries"
                  :val="currentLibraryLabel"
                  @dropdown-change="libraryChanged"
                  :style="'background-color: #fff; border-radius: 8px; border-color: transparent;'"
                  data-test="scene-save-as-libraries-dropdown"
                />
              </div>
            </div>
            <div class="buttons">
              <IdmButton
                :class="'cancel-btn'"
                :btnType="'secondary'"
                :text="'Cancel'"
                @click="isHover = false"
                data-test="scene-save-as-cancel-button"
              ></IdmButton>
              <IdmButton
                class="save-as-btn"
                :btnType="'primary'"
                :text="saving ? '' : 'Save New Scene'"
                @click.stop="saveAs"
                data-test="scene-save-as-button"
              >
                <IdmLoader v-if="saving" style="width: 20px; height: 20px" />
              </IdmButton>
            </div>
          </div>
        </template>
      </IdmAccordion>

      <div class="row2" @click="downloadMp4" data-test="scene-save-download-mp4-button">
        <span>Download as MP4</span>
      </div>

      <div class="row2" @click="downloadGif" data-test="scene-save-download-gif-button">
        <span>Download as GIF</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import fileSaver from 'file-saver';
import AppAccordion from '@/components/AppAccordion';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { store } from '@/store';
import { mapMutations, mapActions } from 'vuex';
import sceneCacheStorage from '@/helper/cache';
import { fontsCache } from '@/helper/fontsCache';

export default {
  name: 'sceneSave',

  components: {
    AppAccordion,
  },

  props: ['scene', 'video', 'videoUrl', 'gifUrl', 'libraryId'],

  data() {
    return {
      showSuccessMsg: false,
      isHover: false,
      variablesSet: false,
      sceneName: null,
      saving: false,
      currentLibrary: {},
    };
  },

  computed: {
    menuItems() {
      let arr = [
        { expanded: false, title: 'Save', content: 'save' },
        { expanded: false, title: 'Save as', content: 'save-as' },
      ];

      if (!this.isOwner) {
        arr.shift();
      }

      return arr;
    },
    isOwner() {
      return this.$auth.user.details.accountId === this.scene.owner;
    },
    currentLibraryLabel() {
      return _.get(this.currentLibrary, 'label', '');
    },
    currentLibraryID() {
      return _.get(this.currentLibrary, 'value', 0);
    },
    libraries() {
      const libraries = this.$store.getters['builder/libraries'];
      return _.map(libraries, (item) => {
        let namePostfix = ' (' + item.library_id + ')';
        let name =
          (item.name + namePostfix).length > 28
            ? item.name.substring(0, 25 - namePostfix.length).trim() + '...'
            : item.name;

        return {
          label: name + namePostfix,
          value: item.library_id,
        };
      }).sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase()
          ? 1
          : b.label.toLowerCase() > a.label.toLowerCase()
          ? -1
          : 0
      );
    },
    idmScene() {
      return this.$store.getters['builder/idmScene'];
    },
  },

  methods: {
    ...mapMutations('errors', ['setError', 'handleErrorStatus']),
    ...mapActions('builder', ['saveScene', 'selectNode']),
    clearSceneCache() {
      sceneCacheStorage.clearSceneCache(this.scene.id);
      // fontsCache.clearSceneFonts(this.scene.id);
    },
    showSuccessSaveMsg() {
      this.isHover = false;
      this.showSuccessMsg = true;
      setTimeout(() => {
        this.showSuccessMsg = false;
      }, 2000);
    },
    initVariables() {
      if (this.variablesSet) {
        return;
      }

      this.sceneName = this.scene.display_name + ' copy';
      let library = _.find(this.libraries, ['value', parseInt(this.libraryId)]);
      this.currentLibrary = library ? library : this.libraries[0];
      this.variablesSet = true;
    },
    mouseEnterEvent() {
      this.initVariables();
      this.isHover = true;
    },
    mouseLeaveEvent(el) {
      if (this.saving) {
        return;
      }

      const elRect = this.$refs.popupEl.getBoundingClientRect();
      const btnRect = this.$refs.popupBtn.getBoundingClientRect();
      const isBtnLeft =
        el.pageY <= btnRect.top ||
        el.pageY >= btnRect.bottom + 10 ||
        el.pageX <= btnRect.left ||
        el.pageX >= btnRect.right;
      const isElLeft =
        el.pageY <= elRect.top + 10 ||
        el.pageY >= elRect.bottom ||
        el.pageX <= elRect.left ||
        el.pageX >= elRect.right;

      if (isElLeft && isBtnLeft) {
        this.isHover = false;
      }
    },
    libraryChanged(val) {
      this.currentLibrary = val;
    },
    sceneNameChanged(val) {
      this.sceneName = val;
    },
    getOriginalScene(sceneName) {
      function removeCustomProps(node) {
        return _.omit(node, [
          'placeholder',
          'media_type',
          'name',
          'edited',
          'renamed',
          'custom_start_time',
        ]);
      }

      const media = _.filter(this.scene.nodes, (node) => {
        return (
          node.media_type === 'color' ||
          node.media_type === 'image' ||
          node.media_type === 'video'
        );
      });
      const text = _.filter(this.scene.nodes, { media_type: 'text' });
      const audio = _.filter(this.scene.nodes, { media_type: 'audio' });

      const idmScene = this.idmScene;
      if (sceneName) {
        idmScene.scene_name = sceneName;
      }

      idmScene.description = this.scene.description;
      idmScene.tags = this.scene.tags;
      idmScene.media = _.map(media, removeCustomProps);
      idmScene.text = _.map(text, removeCustomProps);
      idmScene.audio = _.map(audio, removeCustomProps);

      return idmScene;
    },
    save() {
      if (this.saving) {
        return;
      }

      const { mutate, loading, onDone, onError } = useMutation(
        gql`
          mutation saveScene($id: ID!, $scene: JSON) {
            saveScene(id: $id, scene: $scene)
          }
        `,
        { fetchPolicy: 'no-cache' }
      );
      mutate({
        id: this.scene.id,
        scene: this.getOriginalScene(''),
      });
      onDone((res) => {
        if (res.data.saveScene?.response.status === 'Error') {
          this.handleErrorStatus({ requestSlug: 'saveScene', response: res });
        } else {
          this.showSuccessSaveMsg();
          this.clearSceneCache();
          this.saveScene(this.scene);
        }
      });
      onError((err) => {
        this.setError({
          error_code: err.extensions?.code,
          error_description: err.message,
        });
      });
      this.saving = loading;
    },
    saveAs() {
      if (this.saving) {
        return;
      }

      const { mutate, loading, onDone, onError } = useMutation(
        gql`
          mutation saveSceneAs($libraryId: ID!, $scene: JSON) {
            saveSceneAs(libraryId: $libraryId, scene: $scene)
          }
        `,
        { fetchPolicy: 'no-cache' }
      );
      mutate({
        libraryId: this.currentLibraryID,
        scene: this.getOriginalScene(this.sceneName.trim()),
      });
      onDone((res) => {
        this.handleErrorStatus({ requestSlug: 'saveSceneAs', response: res });
        const success = _.get(res, ['data', 'saveSceneAs', 'success'], false);
        if (success) {
          this.clearSceneCache();
          this.selectNode();
          this.showSuccessSaveMsg();

          setTimeout(() => {
            this.$router.push({
              name: 'scene_editor',
              params: {
                sceneId: _.get(res, ['data', 'saveSceneAs', 'response'], this.scene.id),
              },
              query: { libraryId: this.currentLibraryID },
              force: true,
            });
          }, 1500);
        }
      });

      onError((err) => {
        this.setError({
          error_code: err.extensions?.code,
          error_description: err.message,
        });
      });

      this.saving = loading;
    },
    downloadFile(url, fileName) {
      fileSaver.saveAs(url, fileName);
    },
    downloadMp4() {
      let videoUrl = this.videoUrl.replace('.m3u8', '.mp4');
      this.downloadFile(videoUrl, this.scene.display_name + '.mp4');
    },
    downloadGif() {
      this.downloadFile(this.gifUrl, this.scene.display_name + '.gif');
    },
  },
};
</script>

<style lang="scss">
.mt-10 {
  margin-top: 10px;
}

.dropdown-action {
  position: relative;
}

.success-msg {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  min-width: 300px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #1bd34f;
  box-shadow: 0 5px 20px rgba(7, 53, 74, 0.4);
  border-radius: 8px;
  z-index: 9999999;
  padding: 0 12px;

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
}

.dropdown-content {
  max-width: 376px;
  min-width: 376px;
  top: calc(100% + 10px);
  right: 0;
  position: absolute;
  background-color: #fafafa;
  box-shadow: 0 5px 20px rgba(7, 53, 74, 0.4);
  padding: 0;
  border-radius: 8px;
  font-size: 16px;
  z-index: 9999999;

  .idm-accordion {
    border-radius: 8px 8px 0 0;
  }

  .idm-accordion.k-panelbar > .k-panelbar-header > .k-link .k-icon {
    color: #07354a !important;
  }

  .k-item {
    border: none !important;

    &:first-child {
      .k-link {
        border-radius: 8px 8px 0 0;
      }
    }

    .k-link {
      padding: 8px 16px !important;
      color: #07354a !important;
      background: #fafafa !important;

      &:hover {
        cursor: pointer;
        background: #def3fe !important;
      }
    }

    &.k-expanded {
      .k-link {
        font-weight: 600 !important;
        background-color: #def3fe !important;
      }

      .custom-template {
        background-color: #cdedfe;
      }
    }
  }

  .custom-template {
    padding: 0 !important;
  }

  .accordion-content {
    padding: 11px 16px 19px 16px !important;
    margin: 0 !important;
    color: #07354a;

    .inputs {
      margin-top: 10px;

      .idm-input-container .idm-input.k-input:focus-within {
        border: none;
      }

      .idm-input-container .idm-input.k-input .k-input-inner:focus {
        width: 100%;
        height: 100%;
        border: 1px solid #62d9ff !important;
      }

      .k-dropdownlist.k-picker.idm-dropdown[aria-expanded='true'] {
        border: 1px solid #62d9ff !important;
      }
    }

    .idm-btn {
      &.save-btn,
      &.save-as-btn {
        min-width: 145px;
        justify-content: center;

        svg g {
          g {
            &:nth-child(1) g path {
              color: #000;
              stroke: rgb(230, 246, 254);
            }

            &:nth-child(2) g path {
              color: #000;
              stroke: rgb(149, 218, 255);
            }

            &:nth-child(3) g path {
              color: #000;
              stroke: rgb(7, 53, 74);
            }

            &:nth-child(4) g path {
              color: #000;
              stroke: rgb(26, 109, 255);
            }
          }
        }
      }
      &.save-btn {
        min-width: 84px;
      }
    }

    .cancel-btn {
      color: #07354a;
      background-color: #eef9ff;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 100%;
    height: 10px;
  }

  .accordion-tab {
    .acc-title {
      padding: 8px 16px;
    }

    .acc-content {
      padding: 11px 16px 19px 16px;
      margin: 0;
    }
  }

  .idm-input {
    margin-top: 16px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;

    .idm-btn {
      margin-left: unset;
    }
  }
}

.save {
  .k-animation-container.k-animation-container-relative {
    border-radius: 0 !important;
  }

  .row2 {
    color: #07354a;
    background: #fafafa;

    &:hover {
      cursor: pointer;
      background-color: #def3fe;
    }
  }

  .preview {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background-color: black;
      opacity: 0.7;
    }
  }
}

.row2 {
  padding: 7px 16px 10px;

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
}

.dropdown-content.save .libraries .k-dropdownlist.idm-dropdown {
  width: 100%;
}

.libraries {
  margin-top: 16px;
}
</style>

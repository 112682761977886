<template>
  <div class="filterOrder">
    <div class="filter-item">
      <span class="filter-title">Sort by</span>
      <IdmDropdown class="mr-5" :items="filterItems" :val="filter" tree @dropdown-change="handleFilterChange" data-test="node-filter-filter-dropdown"/>
    </div>

    <div class="filter-item">
      <span class="filter-title">Show</span>
      <IdmDropdown :items="sortItems" :val="sort" @dropdown-change="$emit('sortEvent', $event)" data-test="node-filter-sort-dropdown"/>
    </div>

  </div>
</template>

<script>
import {Filter, Sort} from "@/helper/consts";

export default {
  name: 'nodeFilter',

  props: {
    filter: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    filterItems: [
      {label: "All", value: Filter.ALL},
      {label: "Media", items: [
        {text: "Images", value: Filter.IMAGE}, // Media subcategory
        {text: "Videos", value: Filter.VIDEO}, // Media subcategory
        {text: "Colors", value: Filter.COLOR}, // Media subcategory
      ]}, // Video, Image, Color subcategories
   
      {label: "Text", value: Filter.TEXT},
      {label: "Audio", value: Filter.AUDIO},
    ],
    sortItems: [
      {label: "Timeline", value: Sort.TIMELINE},
      {label: "Alphabetically", value: Sort.ALPHABET},
      {label: "Edited items first", value: Sort.EDITED_ASC},
      {label: "Edited items last", value: Sort.EDITED_DESC}
    ],
  }),
  methods:{
    handleFilterChange(e){
      let item = this.filterItems.find(obj => obj.value === e);
      if(!item){
        const obj = this.filterItems.find(obj => obj.label === 'Media');
        item = obj.items.find(obj => obj.text === e)
      }
      this.$emit('filterEvent', item.value);
    }
  }

}
</script>

<style lang="scss" scoped>
.mr-5 {
  margin-right: 5px;
}

.filterOrder {
  display: flex;
  margin-bottom: 24px;
  column-gap: 8px;
  padding-right: 32px;

  .filter-item {
    width: 50%;
    display: flex;
    flex-direction: column;

    .filter-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #839AA4;
    }

    :deep() .idm-dropdown {
      margin-top: 5px;
    }
  }
}
</style>
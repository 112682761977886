import { createRouter, createWebHistory } from "vue-router"

import editor from '../views/editor'
import editor_home from '../views/editor/homePage'
import editor_scene from '../views/editor/scenePage'

const routes = [
    {
        path: '/',
        name: 'editor',
        component: editor,
        children: [
            // {path: '/', name: 'editor_home', component: editor_home},
            {path: '/:sceneId(\\d+)', name: 'scene_editor', component: editor_scene, props: true},
        ],
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
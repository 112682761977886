<template>
  <div class="dropzone-wrapper">
    <div
      class="dropzone"
      :class="{ 'dropzone-active': active }"
      @dragenter="toggleActive"
      @dragleave="toggleActive"
      @dragover.prevent
      @drop.prevent="handleDrop"
      ref="dropzone"
    >
      <div class="upload-wrapper" v-if="!progressVisible">
        <FileUploader
          dropzone-part
          @file-uploaded="handleFileUpload"
          @wrong-format-file-upload="showModal"
        ></FileUploader>
        <span class="text">Drop media here</span>
      </div>
      <div class="progress-wrapper" v-else>
        <div class="progress-bar" :style="progressStyles"></div>
      </div>
    </div>
    <div class="file-name" v-if="file || fileName">
      <div class="icon-wrapper" @click="playAudio" v-if="audioType">
        <img src="@/assets/icons/play.svg" alt="play" />
      </div>
      <span class="content">{{ file?.name || fileName }}</span>
      <div v-show="showDelete" class="delete-wrapper" @click="deleteFile">
        <img src="@/assets/icons/delete.svg" alt="delete" />
      </div>
    </div>
    <div class="popup-bg" v-if="unsupportedFormatModalIsShown">
      <IdmPopup
        title="Wrong file format"
        :paragraphs="['This file format is not supported!']"
      >
        <IdmButton btnType="secondary" text="Close" @click="closeModal" />
      </IdmPopup>
    </div>
  </div>
</template>

<script>
import FileUploader from '@/components/FileUploader';
import getMediaType from '@/helper/getMediaType';

export default {
  name: 'Dropzone',
  components: {
    FileUploader,
  },
  props: {
    progressTotal: {
      type: Number,
      default: null,
    },
    progressLoaded: {
      type: Number,
      default: null,
    },
    audioType: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: null,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    active: false,
    file: null,
    audio: null,
    unsupportedFormatModalIsShown: false,
  }),
  computed: {
    progressStyles() {
      return {
        width: this.progressLoaded
          ? (this.progressLoaded / this.progressTotal) * 100 + '%'
          : 0,
      };
    },
    progressVisible() {
      return this.progressLoaded && this.progressLoaded !== this.progressTotal;
    },
  },
  watch: {
    file(newVal) {
      !newVal || this.$emit('file-drop', this.file);
    },
    progressTotal(newVal) {
      if (newVal === null) this.file = null;
    },
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
    },
    handleDrop(e) {
      const file = e.dataTransfer.files[0];
      const mediaType = getMediaType(file.name);
      this.toggleActive();
      if (!mediaType) {
        this.showModal();
        return;
      }
      this.file = file;
      this.createAudio();
    },
    handleFileUpload(file) {
      this.file = file;
      this.createAudio();
    },
    deleteFile() {
      this.file = this.audio = null;
      this.$emit('clear-changes');
    },
    playAudio() {
      this.audio.paused ? this.audio.play() : this.audio.pause();
    },
    createAudio() {
      if (this.audioType) {
        this.audio = new Audio(URL.createObjectURL(this.file));
      }
    },
    showModal() {
      this.unsupportedFormatModalIsShown = true;
    },
    closeModal() {
      this.unsupportedFormatModalIsShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone-wrapper {
  .dropzone {
    min-height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 354 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='353' height='71' rx='7.5' fill='white' stroke='%235A7886' stroke-dasharray='8 8'/%3E%3C/svg%3E");
    background-size: 100% auto;
    background-position: 0 0;
    background-repeat: no-repeat;
    padding: 19px;
    border-radius: 8px;
    transition: background-color 0.2s ease-in;

    &.dropzone-active {
      background-color: #f3f3f3;
    }

    .upload-wrapper {
      display: flex;
      align-items: center;
      gap: 34px;
      margin: auto;

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #5a7886;
      }
    }
  }

  .progress-wrapper {
    width: 100%;
    height: 12px;
    background: #e2e9ed;
    border-radius: 8px;

    .progress-bar {
      background: linear-gradient(90deg, #3dbcff 0%, #1a6dff 100%);
      border-radius: 8px;
      height: 100%;
      transition: width 0.2s ease;
    }
  }

  .file-name {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #07354a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: unset;
    .content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-height: 22px;
    }
    .delete-wrapper {
      width: 32px;
      height: 32px;
      padding: 5.5px 10px;
      background: #e2e9ed;
      border-radius: 8px;
      cursor: pointer;
    }
    .icon-wrapper {
      background: #e2e9ed;
      border-radius: 4px;
      padding: 11px;
      width: 32px;
      height: 32px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.popup-bg {
  left: 0;
}
</style>

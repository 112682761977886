export default (fileName) => {
  let mediaType = null;
  const fileExtension = fileName.split(".").reverse()[0];

  switch (fileExtension.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "png":
      mediaType = "image";
      break;
    case "mp4":
    case "flv":
    case "jet":
    case "webm":
    case "mov":
      mediaType = "video";
      break;
    default:
      mediaType = undefined;
  }

  return mediaType;
};

<template>
  <div class="debugbar" :class="{fs:fs}">
    <div class="debugbar-resize"></div>
    <div class="debug-header">
      <div class="left">
        <div @click="activeTab = 1" class="debug-tab" :class="{active:activeTab == 1}" data-test="debug-bar-selected-node-button">Selected node</div>
        <div @click="activeTab = 2" class="debug-tab" :class="{active:activeTab == 2}" data-test="debug-bar-preview-log-button">Preview Log</div>
        <div @click="activeTab = 3" class="debug-tab" :class="{active:activeTab == 3}" data-test="debug-bar-scene-button">Scene</div>
      </div>

      <div class="right">
        <div>
          <label class="label">
            <input
                type="checkbox"
                @change="$emit('showPreviewImgDebug', $event.target.checked)"
                v-model="showPreviewImgDebug"
                data-test="debug-bar-show-preview-image-radio"
            >
            previewImg debug
          </label>
        </div>
        <div>
          <label class="label">
            <input
                type="checkbox"
                @change="$emit('showImagesProgressBar', $event.target.checked)"
                v-model="showImagesProgressBar"
                data-test="debug-bar-show-images-progress-radio"
            >
            showImagesProgressBar
          </label>
        </div>

        <IdmButton v-show="!showBody" :btnType="'small edit'" @click="showBody = true"><IdmIcon name="arrow-up" data-test="debug-bar-show-body-up-button" /></IdmButton>
        <IdmButton v-show="showBody" :btnType="'small edit'" @click="showBody = false"><IdmIcon name="arrow-down" data-test="debug-bar-show-body-down-button" /></IdmButton>

        <IdmButton :btnType="'small edit'" @click="fs = !fs"><IdmIcon name="expand" data-test="debug-bar-small-edit-button" /></IdmButton>
        <IdmButton :btnType="'small edit'" @click="setDebugMode(false)"><IdmIcon name="x" data-test="debug-bar-small-edit-set-button" /></IdmButton>

      </div>

    </div>

    <div v-show="showBody" class="debugbar-body" :class="{log:activeTab == 2}">
      <div v-if="selectedNode && activeTab == 1" class="selected-section">
        <pre class="selected-node">
            <p><strong>key:</strong> {{ selectedNode.key }}</p>
            <p><strong>name:</strong> {{ selectedNode.name }}</p>
            <p><strong>media_type:</strong> {{ selectedNode.media_type }}</p>
            <p><strong>val:</strong> {{ selectedNode.val }}</p>
          </pre>
      </div>

      <div v-if="activeTab == 2">
        <div v-for="(m,i) in debugLog">
          {{ m[0] - debugLog[0][0] }} : {{ m[1] }}
        </div>
      </div>

      <div v-if="activeTab == 3">
        <div style="display: flex;">
          <div style="width:33.333%">sceneResult: <JsonViewer :value="sceneResult" sort theme="jv-light"/></div>
          <div style="width:33.333%">scene: <JsonViewer :value="scene" sort theme="jv-light"/></div>
          <div style="width:33.333%">preview: <JsonViewer :value="preview" sort theme="jv-light"/></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "debugBar",

  data: () => ({
    showBody: true,
    activeTab: 2,
    fs:false,
  }),

  props: {
    showPreviewImgDebug: {
      type: Boolean
    },
    showImagesProgressBar: {
      type: Boolean
    },
    debugLog: {
      type: Array
    },
    scene:{
      type: Object
    },
    preview:{
      type: Object
    },
    sceneResult:{
      type: Object
    },
  },

  computed: {
    selectedNode() {
      return this.$store.getters['builder/selectedNode']
    },
  },

  methods: {
    ...mapActions('builder', [
      'setDebugMode'
    ])
  }
}
</script>

<style lang="scss" scoped>
.debugbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #fff;
  z-index:100;

  &.fs{
    height:100vh;
    .debugbar-body {
      max-height: calc(100vh - 30px);
    }
  }

  .debugbar-resize {
    display: block !important;
    height: 3px;
    margin-top: -4px;
    width: 100%;
    cursor: ns-resize;
    border-top: none;
    border-bottom: 0;
    background-color: #fa5661;
  }

  .debug-header {
    display: flex;
    justify-content: space-between;
    min-height: 30px;
    line-height: 20px;
    text-shadow: 1px 1px #fff;
    background-color: #f5f5f5;

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .right {
      column-gap: 8px;

      .label {
        user-select: none;
      }
    }

    .debug-tab {
      padding: 5px 8px;
      border-left: 1px solid #ddd;
      font-size: 13px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: #ebebeb;
      }

      &.active {
        background-color: #fa5661;
        color: #fff !important;
        text-shadow: 1px 1px #bf3039;
      }
    }
  }

  .debugbar-body {
    max-height: 200px;
    overflow: auto;
    padding: 10px;
    border-top: 1px solid #ddd;

    .selected-section {
      .selected-node {
        font-size: 10px;
      }
    }

    &.log{
      height: 150px;
      padding: 5px;
      font-size: 11px;
    }
  }

}

</style>
<template>
  <div class="dropdown-action">
    <IdmButton
        class="dropdown-trigger"
        :btnType="'info'"
        :text="''"
        @mouseenter="hover=true"
        @mouseleave="hover=false"
        data-test="scene-info-dropdown-trigger-button"
    />
    <div
        v-if="scene && hover"
        class="dropdown-content"
        @mouseenter="hover=true"
        @mouseleave="hover=false"
    >
      <div class="header">
        <span class="scene-id">Scene ID: {{ scene.id }}</span>
        <IdmButton v-if="copied" :btnType="'small success'" :text="'Copied'" @click.stop="copyId(scene.id)" data-test="scene-info-copied-button">
          <IdmIcon name="v"/>
        </IdmButton>
        <IdmButton v-else class="copy-btn" :btnType="'small secondary'" :text="'Copy ID'" @click.stop="copyId(scene.id)" data-test="scene-info-copy-id-button">
          <div class="icon"></div>
        </IdmButton>
      </div>

      <div class="row">Frame per second (FPS): {{ scene.fps }}</div>
      <div class="row">Resolution: {{ resolution }}</div>
      <div class="row">Aspect ratio: {{ aspectRatio }}</div>
      <div class="row">Original duration: {{ duration }}</div>
      <div class="row">Scene builder version: 3.6</div>

      <div class="row row-descr">
        <span>Scene description</span>
        <IdmTextarea
            :text="scene.description"
            :placeholder="'This is a description'"
            @input="inputDescription"
            data-test="scene-info-description-textarea"
        />
      </div>

      <div class="row row-tags">
        <span>Tags (Separate by ;)</span>
        <IdmInput
            :placeholder="'Intro;Outro'"
            :tags="true"
            :tagsList="scene.tags"
            @inputTags="inputTags"
            data-test="scene-info-tags-input"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import sceneCacheStorage from "@/helper/cache";

export default {
  name: "sceneInfo",

  props: ['scene', 'duration'],

  data() {
    return {
      hover: false,
      copied: false
    }
  },

  watch: {
    hover(newVal) {
      if (!newVal) {
        this.copied = false
      }
    }
  },

  computed: {
    resolution() {
      return this.scene.width + 'x' + this.scene.height
    },
    aspectRatio() {
      const gcd = this.gcd(this.scene.width, this.scene.height)
      return this.scene.width / gcd + ":" + this.scene.height / gcd
    }
  },

  methods: {
    ...mapActions('builder', ['updateSceneDescription', 'updateSceneTags']),
    gcd(width, height) {
      return (height === 0) ? width : this.gcd(height, width % height);
    },
    copyId(id) {
      navigator.clipboard.writeText(id).then(
          () => {
            this.copied = true
          },
          (err) => {
            alert("Can not copy", err);
          }
      )
    },
    inputDescription(e) {
      this.updateSceneDescription(e.target.value);
      sceneCacheStorage.updateSceneCache(this.scene);
    },
    inputTags(tagsData) {
      const tags = _.reject(tagsData, (tag) => {
        return tag === ''
      });
      this.updateSceneTags(tags);
      sceneCacheStorage.updateSceneCache(this.scene);
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-action {
  margin-left: 24px;
  position: relative;
}

.dropdown-content {
  top: calc(100% + 10px);
  width: 376px;
  left: -154px;
  position: absolute;
  background-color: #FAFAFA;
  box-shadow: 0 5px 20px rgba(7, 53, 74, 0.4);
  padding-top: 14px;
  padding-bottom: 12px;
  border-radius: 8px;
  font-size: 16px;
  z-index: 9999999;

  .copy-btn {
    .icon {
      width: 16px;
      height: 16px;
      background-image: url(~@/assets/icons/copy.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  
  &:after {
    content: "";
    position: absolute;
    top: -10px;
    right: 0;
    width: 100%;
    height: 10px;
  }

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 8px;

    .scene-id {
      color: #07354A;
      font-weight: 700;
    }
  }

  .row {
    padding: 7px 16px 10px;
    border-bottom: 1px solid #EEEEEE;
    color: #07354A;

    &.row-descr {
      margin-top: 7px;
      padding-top: 0;
      padding-bottom: 0;
      border: none;
    }

    &.row-tags {
      margin-top: 2px;
      padding-bottom: 0;
      border: none;

      .idm-input-container {
        margin-top: 11px;
      }
    }

    .idm-textarea {
      margin-top: 7px;
      padding-top: 2px;
      padding-left: 7px;
      color: #5A7886;
    }
  }
}
</style>
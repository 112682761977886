import standardization from "@/helper/standardization";
import _ from 'lodash'
import { reactive, computed } from 'vue'
import {MediaType, TextFields, MediaFields, BrandLayerTypes} from "@/helper/consts";
import sceneCacheStorage from "@/helper/cache";
import brandsHelper from "@/helper/brands";
import getMediaType from "@/helper/getMediaType";

const state = {
  idmScene:null,
  originalScene:null,
  actualScene:null,
  libraries:[],
  brands:[],
  updatedNodes: [],
  currentBrandId: null,
  nodesByKey:null,
  hoveredNode:null,
  hoveredOnCanvas:false,
  selectedNode:null,
  debugMode: false,
  useCache: true,
  previewLoading: false
}

const getters = {
  idmScene (state) {
    return state.idmScene;
  },
  scene (state) {
    return state.actualScene;
  },
  libraries (state) {
    return state.libraries;
  },
  brands (state) {
    return state.brands;
  },
  updatedNodes(state) {
    return state.updatedNodes;
  },
  currentBrandId (state) {
    return state.currentBrandId;
  },
  hoveredNode (state) {
    return state.hoveredNode;
  },
  hoveredOnCanvas (state) {
    return state.hoveredOnCanvas;
  },
  selectedNode (state) {
    return state.selectedNode;
  },
  debugMode (state) {
    return state.debugMode;
  },
  previewLoading: (state) => state.previewLoading
}

const mutations = {
  setIdmScene(state, {getters, idmScene}) {
    state.idmScene = idmScene
  },
  updateScene (state, {getters, newScene, reSave, useCache}) {
    if(reSave || !state.originalScene || state.originalScene.id !== newScene.id){
      state.originalScene = JSON.parse(JSON.stringify(newScene))
    }
    state.actualScene = newScene

    if (state.useCache && useCache && !reSave) {
      let sceneCache = sceneCacheStorage.getSceneCache(state.actualScene.id)

      if (sceneCache) {
        state.currentBrandId = sceneCache.brandId;
        state.updatedNodes = _.get(sceneCache, ['updatedNodes'], []);
      }

      if (sceneCache && sceneCache.last_modified === state.actualScene.last_modified) {
        state.actualScene.tags = sceneCache.tags
        state.actualScene.description = sceneCache.description

        const availableFields = [...TextFields, ...['name', 'val', 'is_hidden', 'media_type', 'tags', 'branding'], ...MediaFields]
        _.forEach(sceneCache.nodes, (nodeCache) => {
          let node = _.find(state.actualScene.nodes, {key: nodeCache.key})
          
          if (node) {
            _.forEach(nodeCache, (value, key) => {
              if (_.indexOf(availableFields, key) >= 0) {
                let val = JSON.parse(JSON.stringify(value))
                _.set(node, key, val)
              }
            })
          }
        })
      }
    }

    state.nodesByKey = {
      original:{},
      actual:{}
    }

    state.originalScene.nodes.forEach(node => {
      state.nodesByKey.original[node.key] = node
    })
    state.actualScene.nodes.forEach(node => {
      state.nodesByKey.actual[node.key] = node
    })

    state.actualScene.nodes.forEach(node => {
      const originalNode = state.nodesByKey.original[node.key]
      if (node.media_type === MediaType.TEXT) {
        node.edited = computed(() =>
        !_.isEqual(_.pick(node, TextFields), _.pick(originalNode, TextFields))
        || !_.isEqual(node.tags.sort(), originalNode.tags.sort())
        || !_.isEqual(node.description, originalNode.description))
      } else {
        node.edited = computed(() =>
            JSON.stringify(node.val) != JSON.stringify(originalNode.val)
            || JSON.stringify(node.alignment_scale_type) != JSON.stringify(originalNode.alignment_scale_type)
            || JSON.stringify(node.alignment_x_align) != JSON.stringify(originalNode.alignment_x_align)
            || JSON.stringify(node.alignment_y_align) != JSON.stringify(originalNode.alignment_y_align)
            || node.is_hidden !== originalNode.is_hidden
            || !_.isEqual(node.tags.sort(), originalNode.tags.sort())
            || !_.isEqual(node.description, originalNode.description)
        )
      }

      node.renamed = computed(() => originalNode.name !== node.name)
    })
    
    state.actualScene.edited = computed(() =>
        _.isObject(_.find(state.actualScene.nodes, {edited: true}))
        || state.actualScene.description !== state.originalScene.description
        || !_.isEqual(state.actualScene.tags.sort(), state.originalScene.tags.sort())
    )
    state.actualScene.renamed = computed(() => _.isObject(_.find(state.actualScene.nodes, {renamed: true})))
  },
  updateSceneDescription(state, data) {
    state.actualScene.description = data
  },
  updateSceneTags(state, data) {
    state.actualScene.tags = data
  },
  setLibraries(state, libraries) {
    state.libraries = libraries
  },
  setBrands(state, brands) {
    state.brands = brands
  },
  setCurrentBrandId(state, brandId) {
    state.currentBrandId = brandId;
    sceneCacheStorage.updateBrandId(state.actualScene, state.currentBrandId);
  },
  applyBrandStyles(state, brandId) {
    // RESET BRANDED NODES TO DEFAULT/CHANGED
    if (state.currentBrandId) {
      const changedNodes = _.filter(state.actualScene.nodes, {branding: true});
      changedNodes.forEach(changedNode => {
        const originalNode = state.nodesByKey.original[changedNode.key];
        changedNode.media_type = originalNode.media_type;
        if (originalNode.media_type === MediaType.TEXT) {
          const filteredVal = _.map(changedNode.val, (nodeVal) => {
            return _.omit(nodeVal, ['font_path', 'font_checksum'])
          })

          changedNode.asset_path = originalNode.asset_path;
          changedNode.val = filteredVal;
        } else {
          changedNode.val = JSON.parse(JSON.stringify(originalNode.val));
        }

        changedNode.branding = false;
      })
    }

    // APPLY BRAND STYLES
    if (brandId) {
      let brandedNodes = {};

      state.actualScene.nodes.forEach(node => {
        const tags = _.map(node.tags, (tag)=>{
          return {name: tag, value: null}
        });
        _.set(brandedNodes, node.name, {
          placeholder: null,
          tags: tags
        })
      });

      const brandKit = _.find(state.brands, {'_id': brandId});
      const brandLayers = _.get(brandKit, 'layres', []);

      brandLayers.forEach(brandLayer => {
        if (_.isEmpty(brandLayer)) {
          return
        }

        const reg = brandsHelper.layerRegex(brandLayer);

        state.actualScene.nodes.forEach(node => {
          if (node.media_type === MediaType.TEXT && brandLayer.type !== BrandLayerTypes.TEXT) {
            return;
          }

          if (node.media_type !== MediaType.TEXT && brandLayer.type !== BrandLayerTypes.MEDIA) {
            return;
          }

          // ADD TAGS
          node.tags.forEach(tag => {
            if (tag === brandLayer.name.toLowerCase()) {
              const brandedNode = _.get(brandedNodes, [node.name]);

              const index = _.findIndex(brandedNode.tags, { name: brandLayer.name.toLowerCase() });
              if (index !== -1) {
                brandedNode.tags[index] = _.assign({}, brandedNode.tags[index], { value: brandLayer });
              }
            }
          })

          // ADD PLACEHOLDER
          if (reg.test(node.name)) {
            _.set(brandedNodes, [node.name, 'placeholder'], brandLayer);
          }
        });
      });

      _.forEach(brandedNodes, function(brandedNode, nodeKey) {
        const lastTagWithBrand = _.last(_.filter(brandedNode.tags, tag => tag.value !== null));
        const brandLayer = lastTagWithBrand !== undefined ?  lastTagWithBrand.value : brandedNode.placeholder;
        if (brandLayer) {
          let node = _.find(state.actualScene.nodes, {key: nodeKey});

          brandLayer.values.forEach(objValue => {
            _.forOwn(objValue, (value, key) => {

              if (brandLayer.type === BrandLayerTypes.TEXT) {
                const filteredVal = _.map(node.val, (nodeVal) => {
                  return _.omit(nodeVal, ['font_path', 'font_checksum'])
                })

                node.asset_path = value;
                node.val = filteredVal;
              } else if ((brandLayer.type === BrandLayerTypes.MEDIA)) {
                const mediaType = brandsHelper.isColor(value)
                  ? 'color'
                  : getMediaType(value);

                node.val = mediaType === 'color' ? brandsHelper.getRgbColor(value) : value;
                node.media_type = mediaType;
              }

              node.branding = true;
            });
          })
        }
      })
    }
  },
  hoverNode(state, data) {
    state.hoveredNode = data?.node || null
    state.hoveredOnCanvas = !!data?.node && !!data?.onCanvas
  },
  selectNode(state, node) {
    state.selectedNode = node
  },
  updateNodeValue(state, data) {
    const actualNode = state.nodesByKey.actual[data.node.key]

    switch (actualNode.media_type) {
      case MediaType.TEXT: {
        _.forEach(data.data, (value, key) => {
          if (_.indexOf(TextFields, key) >= 0) {
            let val = key === 'font_size' ? parseInt(value) : value
            _.set(actualNode, key, val)
          }
        })
        break
      }
      case MediaType.VIDEO:
      case MediaType.COLOR:
      case MediaType.IMAGE: {
        actualNode.val = data.data.val
        actualNode.media_type = data.data.media_type
        actualNode.alignment_scale_type = data.data.alignment_scale_type
        actualNode.alignment_x_align = data.data.alignment_x_align
        actualNode.alignment_y_align = data.data.alignment_y_align
        actualNode.if_longer = data.data.if_longer
        !data.data.fileName || (actualNode.fileName = data.data.fileName)
        !data.data.mediaLink || (actualNode.mediaLink = data.data.mediaLink)
        !data.data.color || (actualNode.color = data.data.color)
        break
      }
      default: {
        actualNode.val = data.data.val
      }
    }
  },
  updateNodeTags(state, data) {
    const actualNode = state.nodesByKey.actual[data.node.key]
    actualNode.tags = data.tags
  },
  updateNodeDescription(state, data) {
    const actualNode = state.nodesByKey.actual[data.node.key]
    actualNode.description = data.description
  },
  showNode(state, node) {
    node.is_hidden = false
  },
  hideNode(state, node) {
    node.is_hidden = true
  },
  undoSceneAttributesChanges(state) {
    state.actualScene.tags = state.originalScene.tags
    state.actualScene.description = state.originalScene.description
  },
  undoNodeChanges(state, data) {
    const node = _.get(data, 'node', state.selectedNode);
    const removeTags = _.get(data, 'removeTags', state.selectedNode);
    const originalNode = state.nodesByKey.original[node.key]
    const actualNode = state.nodesByKey.actual[node.key]

    if (actualNode.renamed) {
      actualNode.name = originalNode.name
    }

    if (!actualNode.edited) {
      return
    }

    if (removeTags) {
      actualNode.tags = originalNode.tags
    }

    actualNode.description = originalNode.description

    switch (actualNode.media_type) {
      case MediaType.TEXT: {
        actualNode.name = originalNode.name
        _.forEach(originalNode, (value, key) => {
          if (_.indexOf(TextFields, key) >= 0) {
            if (actualNode.branding && key === 'asset_path') {
              return;
            }
            _.set(actualNode, key, JSON.parse(JSON.stringify(value)))
          }
        })

        const extraKeys = _.pull(_.keys(_.pickBy(actualNode, (val, key) => !_.has(originalNode, key))), 'edited', 'renamed')
        extraKeys.map((extraKey) => {
          delete actualNode[extraKey]
        })
        break
      }
      case MediaType.VIDEO:
      case MediaType.COLOR:
      case MediaType.IMAGE: {
        actualNode.media_type = originalNode.media_type

        if (originalNode.alignment_scale_type) {
          actualNode.alignment_scale_type = originalNode.alignment_scale_type
        }
        if (typeof originalNode.alignment_x_align !== 'undefined' && typeof originalNode.alignment_y_align !== 'undefined') {
          actualNode.alignment_x_align = originalNode.alignment_x_align;
          actualNode.alignment_y_align = originalNode.alignment_y_align;
        }
        if (typeof originalNode.if_longer !== 'undefined') {
          actualNode.if_longer = originalNode.if_longer;
        }

        actualNode.fileName = null
        actualNode.mediaLink = null
        actualNode.color = null

        if (state.currentBrandId && actualNode.branding) {
          const brandKit = _.find(state.brands, {'_id': state.currentBrandId});
          const brandValue = brandsHelper.getNodeBrandValue(actualNode, brandKit);
          _.forOwn(brandValue, (value, key) => {
            actualNode[key] = JSON.parse(JSON.stringify(value));
          })
        }
      }
      default: {
        if (!actualNode.branding) {
          actualNode.val = JSON.parse(JSON.stringify(originalNode.val))
        }
        actualNode.is_hidden = originalNode.is_hidden
      }
    }
  },
  setDebugMode(state, mode) {
    state.debugMode = mode
  },
  changePreviewLoading(state, val){
    state.previewLoading = val;
  }
}

const actions = {
  standardization: ({ commit, getters }, data) => {
    const idmScene = data.scene
    const reSave = false
    const useCache = true
    const newScene = standardization(data.scene, data.placeholders, data.frames)
    commit('setIdmScene', {getters,idmScene})
    commit('updateScene', {getters, newScene, reSave, useCache})
  },
  saveScene({commit, getters}, scene) {
    const newScene = {...state.actualScene}
    newScene.nodes = _.map(state.actualScene.nodes, (node) => {
      delete node.edited
      delete node.renamed
      return node
    })
    newScene.edited = false
    newScene.renamed = false

    const reSave = true
    commit('updateScene', {getters, newScene, reSave})
  },
  updateSceneDescription(context, data) {
    context.commit('updateSceneDescription', data)
  },
  updateSceneTags(context, data) {
    context.commit('updateSceneTags', data)
  },
  setLibraries(context, libraries) {
    context.commit('setLibraries', libraries)
  },
  setBrands(context, brands) {
    context.commit('setBrands', brands)
  },
  applyBrandStyles(context, brandId) {
    context.commit('applyBrandStyles', brandId);
    context.commit('setCurrentBrandId', brandId);
  },
  hoverNode: (context, data) => {
    context.commit('hoverNode', data)
  },
  selectNode: (context, node) => {
    context.commit('selectNode', node || null)
  },
  updateNodeValue: (context, data) => {
    if(!data.node || !data.val){
      new Error('incorrect data');
    }
    context.commit('updateNodeValue', data)
  },
  updateNodeTags: (context, data) => {
    if (!data.node || !data.hasOwnProperty('tags')) {
      new Error('incorrect data');
    }
    context.commit('updateNodeTags', data)
  },
  updateNodeDescription: (context, data) => {
    if (!data.node || !data.hasOwnProperty('description')) {
      new Error('incorrect data');
    }
    context.commit('updateNodeDescription', data)
  },
  showNode: (context, node) => {
    if (!node) {
      new Error('incorrect data');
    }
    context.commit('showNode', node)
  },
  hideNode: (context, node) => {
    if (!node) {
      new Error('incorrect data');
    }
    context.commit('hideNode', node)
  },
  undoNodeChanges: (context, node) => {
    if (node && state.useCache && !state.currentBrandId) {
      sceneCacheStorage.clearNodeCache(state.actualScene.id, node.key)
    }

    context.commit('undoNodeChanges', {
      node: node,
      removeTags: false,
    });
  },
  undoAllNodeChanges(context) {
    context.commit('undoSceneAttributesChanges')

    if (state.useCache && !state.currentBrandId) {
      sceneCacheStorage.clearSceneCache(state.actualScene.id)
    }

    state.actualScene.nodes.forEach(node => {
      context.commit('undoNodeChanges', {
        node: node,
        removeTags: true,
      })
    })
  },
  setDebugMode(context, mode){
    context.commit('setDebugMode', mode)
  },
  changePreviewLoading({commit}, val){
    commit('changePreviewLoading', val)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
import '@babel/polyfill';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import IdmUI from '@idomoo-hub/library';
import posthogPlugin from "./plugins/posthog";
import JsonViewer from "vue3-json-viewer";

import "vue3-json-viewer/dist/index.css";
import './css/main.scss';

const app = createApp(App)
  .use(router)
  .use(store)
  .use(IdmUI, {auth:{
      debug: true,
      api_url: process.env.VUE_APP_API_URL,
      login_url: process.env.VUE_APP_LOGIN_URL,
      logout_url: process.env.VUE_APP_LOGOUT_URL,
      apolloConnectToDevTools: process.env.NODE_ENV !== 'production',
    }})
  .use(JsonViewer);

if(process.env.VUE_APP_DISABLE_POSTHOG != "true"){
  app.use(posthogPlugin);
}
app.mount('#app');

console.log(`%cScene Creator v.${process.env.VUE_APP_VERSION} (BuildTime: ${process.env.VUE_APP_BUILDTIME})%cENV: ${process.env.NODE_ENV}%c${process.env.VUE_APP_GIT_HASH}`,
  'background:#07354A;color:#fff;font-weight:900;padding:3px 5px;border-radius:5px;margin-right:10px',
  'background:#aaa;color:#222;font-weight:900;padding:3px 5px;border-radius:5px;margin-right:10px',
  'background:#aaa;color:#222;font-weight:900;padding:3px 5px;border-radius:5px');
import { reactive, customRef, onBeforeUnmount } from "vue";

function useDebouncedRef(playerId, fps) {
  return customRef((track, trigger) => {

    window[playerId].on(`timeupdate`, trigger)

    onBeforeUnmount(() => {
      window[playerId].off(`timeupdate`, trigger)
    })

    return {
      get() {
        track()
        return window[playerId] && !window[playerId].isDisposed_ ? Math.floor(window[playerId].currentTime() * fps) : 0
      },
      set(frame) {
        if(window[playerId] && !window[playerId].isDisposed_) window[playerId].currentTime(frame/fps)
      }
    }
    
  })
}

export default (playerId, fps = 25) => {
  return reactive(useDebouncedRef(playerId, fps))
}
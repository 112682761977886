export const MediaType = {
    VIDEO: "video",
    IMAGE: "image",
    COLOR: "color",
    TEXT: "text",
    AUDIO: "audio"
}

export const Filter = {
    ALL: "All",
    MEDIA: "Media",
    VIDEO: "Video",
    IMAGE: "Image",
    COLOR: "Color",
    TEXT: "Text",
    AUDIO: "Audio"
}

export const Sort = {
    TIMELINE: "Timeline",
    ALPHABET: "Alphabetically",
    EDITED_ASC: "Edited items first",
    EDITED_DESC: "Edited items last",
}

export const TextFields = [
    'val',
    'is_hidden',
    'wrapping_breakline',
    'wrapping_minimum',
    'wrapping_shrink',
    'font_size',
    'wrapping_truncate',
    'asset_path',
    'text_color',
    'alignment',
    'right_to_left',
    'wrapping_truncate_string',
    'duration_in_seconds',
    'background_rect'
]

export const MediaFields = [
    'alignment_scale_type',
    'alignment_x_align',
    'alignment_y_align',
    'if_longer',
]

export const BrandLayerTypes = {
    TEXT: 'Text',
    MEDIA: 'Media',
}

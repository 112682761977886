<template>
  <div>
    <IdmSubHeader>
      <span class="logo-link">
        <IdmLogo :app="'scene creator'" />
      </span>

      <h1 class="title" v-if="scene">{{ scene.display_name }}</h1>

      <branding @preview="$emit('preview')" />

      <sceneInfo :scene="scene" :duration="duration" />

      <IdmButton
        class="header"
        :btnType="'header'"
        :text="'Clear changes'"
        :disabled="!scene || !scene.edited"
        @click="clearAllChanges"
        data-test="topbar-clear-changes-button"
      ></IdmButton>

      <sceneSave
        class="scene-save"
        :scene="scene"
        :video="video"
        :video-url="videoUrl"
        :gif-url="gifUrl"
        :library-id="libraryId"
      />
    </IdmSubHeader>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import branding from './branding';
import sceneInfo from './sceneInfo';
import sceneSave from './sceneSave';

export default {
  name: 'topbar',

  components: {
    branding,
    sceneInfo,
    sceneSave,
  },

  props: ['scene', 'video', 'videoUrl', 'gifUrl', 'libraryId', 'duration'],

  methods: {
    ...mapActions('builder', ['undoAllNodeChanges']),
    closeScene() {
      this.$emit('close');
    },
    clearAllChanges() {
      this.undoAllNodeChanges();
      this.$emit('preview');
    },
  },
};
</script>

<style lang="scss" scoped>
.idm-subheader .logo-link {
  position: relative;
}

.idm-btn.header.header {
  margin-left: 24px;
}

.scene-save {
  margin-left: 8px;
}

.idm-logo {
  pointer-events: none;
}
</style>

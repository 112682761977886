const state = {
  error: {
    code: null,
    description: null,
    message: null,
  },
  requestErrorPopupIsShown: false,
};

const getters = {
  error(state) {
    return {
      code: state.error.code,
      description: state.error.description,
      message: state.error.message ?? "Something went wrong",
    };
  },
};

const mutations = {
  showRequestErrorPopup(state) {
    state.requestErrorPopupIsShown = true;
  },
  hideRequestErrorPopup(state) {
    state.requestErrorPopupIsShown = false;
  },
  setError(state, error){
    state.error = {
      code: error.error_code,
      description: error.error_description,
      message: error.error_message,
    }
  },
  handleErrorStatus(state, {requestSlug, response}){
    if(_.get(response, ['data', requestSlug, 'status']) === 'Error'){
      const error = _.get(response, ['data', requestSlug, 'errors', '0']);
      return this.commit('errors/setError', error);
    }
    if(_.get(response, ['data', requestSlug, 'success']) === false && _.get(response, ['data', requestSlug, 'response', 'status']) === "Error"){
      const error = _.get(response, ['data', requestSlug, 'response', 'errors', '0']);
      return this.commit('errors/setError', error);
    }
    if(response?.message === 'Internal server error'){
      return this.commit('errors/setError', {
        error_code: 201222.1,
        error_description: 'Internal server error',
        error_message: 'Internal server error',
      });
    }
    if(requestSlug == 'getScene' && _.get(response, 'data.getImsScene') === null){
      return this.commit('errors/setError', {
        error_code: 201222.2,
        error_description: 'Empty response from getScene',
        error_message: 'Empty response from getScene',
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

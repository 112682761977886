<template>
  <div
    class="accordion-tab"
    :class="{ active: isOpen }"
    @click="toggleAccordion"
  >
    <div class="acc-title">
      {{ title }}
      <img class="arrow" src="@/assets/icons/accordion_arrow.svg" alt="arrow" />
    </div>
    <transition name="accordionHeight">
      <div class="acc-content" v-show="isOpen">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppAccordion",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle-accordion', this.isOpen)
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-tab {
  border-bottom: 1px solid #07354a;
  cursor: pointer;
  .acc-title {
    padding: 15px 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 20px;
    color: #07354a;
    .arrow {
      transition: transform 0.3s ease-in;
    }
  }
  .acc-content {
		margin: 24px 0 23px;
  }
  &.active {
    .arrow {
      transform: rotate(90deg);
    }
  }
}

.accordionHeight-enter-active,
.accordionHeight-leave-active {
  transition: max-height 0.3s ease-in-out;
  max-height: 100px;
  overflow: hidden;
}
.accordionHeight-enter-from,
.accordionHeight-leave-to {
  max-height: 0px;
}
</style>

<template>
  <div>
    <IdmAuth>
      <div id="appWrapper">
        <IdmHeader
          :userName="$auth.user.details.firstName + ' ' + $auth.user.details.lastName"
          :userMail="$auth.user.details.email"
          :userID="'' + $auth.user.details.accountId"
          :method="signOut"
        ></IdmHeader>

        <div v-if="requestErrorPopupIsShown" class="popup-bg">
          <IdmPopup
            align="left"
            :title="requestError.message"
            :paragraphs="[
              `If you believe you have received this message in error, please contact the Idomoo account owner or <a href='mailto:support@idomoo.com'>support@idomoo.com</a> and request a new access link.`,
            ]"
            :errorTitle="requestError.code"
            :errorText="requestError.description"
          >
            <IdmButton :btnType="'secondary'" text="Close" @click="closeErrorPopup" data-test="error-popup-close-button" />
          </IdmPopup>
        </div>

        <router-view />
      </div>
    </IdmAuth>
  </div>
</template>

<script>
import { provideApolloClient } from '@vue/apollo-composable';
import { store } from '@/store';
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'App',

  data() {
    return {
      userDetails: null,
      href: null,
    };
  },
  computed: {
    ...mapState('errors', ['requestErrorPopupIsShown']),
    ...mapGetters('errors', { requestError: 'error' }),
  },
  watch: {
    userDetails(val) {
      if (!this.href[1]) {
        this.phUserEntered();
      } else {
        this.phLoggedIn();
      }
    },
    'requestError.description'(newVal) {
      if (newVal) this.showRequestErrorPopup();
    },
    '$auth.initialized'(newVal) {
      if (newVal) this.provideApollo();
    },
    '$auth.tokenOutdated'(newVal) {
      if (newVal) {
        this.phTimeoutReached();
      }
    },
    '$auth.user.details.email'(newVal) {
      if(!process.env.VUE_APP_DISABLE_POSTHOG){
        this.$posthog.people.set({ email: newVal });
        this.$posthog.identify(newVal);
      }
    },
  },

  methods: {
    ...mapMutations('errors', ['hideRequestErrorPopup', 'showRequestErrorPopup']),
    signOut() {
      this.phUserLoggedOut();
      this.$auth.logout();
    },
    closeErrorPopup() {
      this.hideRequestErrorPopup();
      this.$router.go(this.$router.currentRoute);
    },
    provideApollo() {
      const apolloClient = this.$auth.apolloClient;
      console.log('apolloClient version => ', apolloClient.version);
      provideApolloClient(apolloClient);
      this.userDetails = this.$auth.user.details;
      console.log('apollo provided');
    },
    phLoggedIn() {
      this.sendPostHogEvent("User logged in")
    },
    phUserEntered() {
      this.sendPostHogEvent("User entered")
    },
    phUserLoggedOut() {
      this.sendPostHogEvent("User logged out")
    },
    phTimeoutReached() {
      this.sendPostHogEvent("Timeout reached")
    },
    sendPostHogEvent(eventName) {
      const properties = {
        timestamp: Date.now(),
        email: this.userDetails.email,
        pv_account_id: this.userDetails.accountId,
        event_origin: 'idm_custom_event',
        storyboard_id: -1,
        landingpage_id: -1
      }

      if (!process.env.VUE_APP_DISABLE_POSTHOG) {
        this.$posthog.capture(
          eventName,
          properties
        )
      }
    }
  },
  mounted() {
    this.href = window.location.href.split('#');
    if (this.$auth.initialized) {
      this.provideApollo();
    }
  },
};
</script>

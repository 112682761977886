import { ref } from "vue";
import { store } from "@/store";

// by convention, composable function names start with "use"
export function useUploadToS3(file, customContentUrl, mediaUploadEndpoint) {
  const loaded = ref(0);
  const total = ref(0);
  const request = new Promise((resolve, reject) => {
    if (file) {
      const fileReader = new FileReader();
      const chunk = file.slice(0, 261);
      fileReader.onload = async () => {
        const postData = {
          extension: file.name.split(".").pop().toLowerCase(),
          sample: btoa(fileReader.result),
        };

        const response = await fetch(mediaUploadEndpoint, {
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(postData),
        });

        response.json().then(async (response) => {
          const formData = new FormData();
          Object.keys(response.fields).forEach((key) => {
            formData.append(key, response.fields[key]);
          });
          formData.append("file", file, "File");

          let xhr = new XMLHttpRequest();

          xhr.upload.onprogress = (event) => {
            loaded.value = event.loaded;
            total.value = event.total;
          };

          xhr.onloadend = (e) => {
            if (e.target.status >= 200 && e.target.status <= 206) {
              const link = `${customContentUrl}/${response.fields.key}`;
              resolve(link);
            } else {
              store.commit("errors/setError", {
                error_code: e.target.status,
                error_description: e.target.responseText,
                error_message: e.target.statusText,
              });
              console.log(
                `Bad request. Error code: ${e.target.status}, errorText: ${e.target.statusText}`
              );
            }
          };

          xhr.open("POST", response.url);
          xhr.send(formData);
        });
      };
      fileReader.readAsBinaryString(chunk);
    } else {
      reject();
    }
  });

  return { loaded, total, request };
}

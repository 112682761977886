// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/copy.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".dropdown-action[data-v-5858dee1] {\n  margin-left: 24px;\n  position: relative;\n}\n.dropdown-content[data-v-5858dee1] {\n  top: calc(100% + 10px);\n  width: 376px;\n  left: -154px;\n  position: absolute;\n  background-color: #FAFAFA;\n  box-shadow: 0 5px 20px rgba(7, 53, 74, 0.4);\n  padding-top: 14px;\n  padding-bottom: 12px;\n  border-radius: 8px;\n  font-size: 16px;\n  z-index: 9999999;\n}\n.dropdown-content .copy-btn .icon[data-v-5858dee1] {\n  width: 16px;\n  height: 16px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n}\n.dropdown-content[data-v-5858dee1]:after {\n  content: \"\";\n  position: absolute;\n  top: -10px;\n  right: 0;\n  width: 100%;\n  height: 10px;\n}\n.dropdown-content .header[data-v-5858dee1] {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  padding: 0 16px;\n  margin-bottom: 8px;\n}\n.dropdown-content .header .scene-id[data-v-5858dee1] {\n  color: #07354A;\n  font-weight: 700;\n}\n.dropdown-content .row[data-v-5858dee1] {\n  padding: 7px 16px 10px;\n  border-bottom: 1px solid #EEEEEE;\n  color: #07354A;\n}\n.dropdown-content .row.row-descr[data-v-5858dee1] {\n  margin-top: 7px;\n  padding-top: 0;\n  padding-bottom: 0;\n  border: none;\n}\n.dropdown-content .row.row-tags[data-v-5858dee1] {\n  margin-top: 2px;\n  padding-bottom: 0;\n  border: none;\n}\n.dropdown-content .row.row-tags .idm-input-container[data-v-5858dee1] {\n  margin-top: 11px;\n}\n.dropdown-content .row .idm-textarea[data-v-5858dee1] {\n  margin-top: 7px;\n  padding-top: 2px;\n  padding-left: 7px;\n  color: #5A7886;\n}", ""]);
// Exports
module.exports = exports;

<template>
  <div
    class="imagesProgressBar"
    @mouseleave="positionLine.show = false"
    @click="setNewPos"
    data-test="image-progress-bar-set-position-button"
  >
    <div class="images noSelect" :style="imagesStyle" ref="images">
      <div v-for="f in frames" :key="f.id" :style="imageStyle">
        <img v-if="f.status" :src="f.src" alt="frame" />
      </div>
    </div>
    <div
      class="currentPositionWrap"
      @mousemove="mouseMove"
      @mouseenter="positionLine.show = true"
    >
      <div class="currentPosition" :style="currentPositionStyle"></div>
    </div>
    <div class="position" :style="positionStyle"></div>
  </div>
</template>

<script>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import _ from 'lodash';
import { fileAvailabilityCheck } from '@/helper/fileAvailabilityCheck';

export default {
  name: 'imagesProgressBar',
  props: {
    scene: {
      type: Object,
    },
    preview: {
      type: Object,
    },
    frame: {
      type: Object,
    },
    height: {
      type: Number,
      default: 70,
    },
  },

  emits: ['changeTime'],

  data: () => ({
    imageCount: 0,
    frames: [],
    positionLine: {
      show: false,
      left: 0,
    },
  }),

  watch: {
    'preview.requestId'() {
      this.getAllFrames();
    },
    imageCount() {
      this.getAllFrames();
    },
  },

  computed: {
    imageStyle() {
      return `aspect-ratio:${this.scene.width}/${this.scene.height}`;
    },
    imagesStyle() {
      return `height:${this.height}px`;
    },
    currentPositionStyle() {
      const p =
        ((this.frame.current || this.frame.preview) / this.scene.duration.frames) * 100;
      return `left:calc(${p}% - 1px)`;
    },
    positionStyle() {
      return `left:calc(${this.positionLine.left}% - 1px);display:${
        this.positionLine.show ? 'absolute' : 'none'
      }`;
    },
  },

  methods: {
    getFrame(time) {
      return new Promise((resolve) => {
        const { result, loading, error, onResult } = useQuery(
          gql`
            query GetScenePreview($id: ID!, $scene_doc: JSON!, $time_point: Float) {
              getScenePreview(id: $id, scene_doc: $scene_doc, time_point: $time_point)
            }
          `,
          () => ({
            id: this.scene.id,
            time_point: time,
            scene_doc: this.preview.sceneDoc,
            imageOnly: true,
          }),
          { fetchPolicy: 'no-cache' }
        );

        onResult((res) => {
          resolve(
            _.get(
              res,
              ['data', 'getScenePreview', 'output', 'jpg', '0', 'links', 'url'],
              ''
            )
          );
        });
      });
    },

    floor2(num) {
      return Math.floor(num * 100) / 100;
    },

    getAllFrames(sceneId, sceneDoc) {
      const promises = [];
      const timepoints = [0];
      const step = this.floor2(this.scene.duration.seconds / this.imageCount);
      let n = this.imageCount - 1;
      while (n--) {
        timepoints.push(this.floor2(timepoints[timepoints.length - 1] + step));
      }
      console.log('!!!!!!!!!!!!!!!!!!!getAllFrames', timepoints);

      timepoints.forEach((t) => {
        promises.push(this.getFrame(t));
      });

      Promise.all(promises).then((images) => {
        const frames = images.map((url, i) => {
          fileAvailabilityCheck(url, { interval: [10, 10, 50, 50, 100, 100, 500] }).then(
            (status) => {
              this.frames[i].status = status;
            }
          );

          return {
            id: i,
            src: url,
            status: null,
          };
        });

        this.frames = frames;
        console.log('Promise.all frames', frames);
      });
    },

    setImageCount() {
      const ar = this.scene.width / this.scene.height;
      const imgW = ar * this.height;
      const wrapW = this.$refs.images.offsetWidth;
      this.frames = [];
      this.imageCount = Math.floor(wrapW / imgW);
    },

    mouseMove(event) {
      this.positionLine.left = (event.layerX / this.$refs.images.offsetWidth) * 100;
    },

    setNewPos(event) {
      const newPos = this.positionLine.left;
      this.$emit('changeTime', (newPos / 100) * this.scene.duration.seconds);
    },
  },
  mounted() {
    this.ro = new ResizeObserver(this.setImageCount);
    this.ro.observe(this.$refs.images);
    this.setImageCount();
  },
};
</script>

<style scoped>
.imagesProgressBar {
  position: relative;
  border: 1px solid #ccc;
}
.images {
  display: flex;
  justify-content: space-between;
  background: #e5e5e5;
}
.images > div {
  max-height: 100%;
  background: #eee;
}
.images img {
  max-height: 100%;
  opacity: 0.85;
}

.currentPositionWrap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}

.currentPosition,
.position {
  position: absolute;
  width: 2px;
  height: calc(100% + 10px);
  top: -5px;
  background: #1a6dff;
  box-shadow: 1px 0 1px #555;
}
.currentPosition {
  transition: linear 0.25s;
}
.position {
  background: #07354a;
  cursor: pointer;
}
</style>

import posthog from "posthog-js";

export default {
    install(app) {
      app.config.globalProperties.$posthog = posthog.init(
        "phc_JzquB7WAHw4XXoY8vsE0eEwQ4itgSglXjiNMHd2bhiC",
        {
          api_host: "https://app.posthog.com",
          persistence: "localStorage"
        }
      );
    },
};
import _ from "lodash";

export const fontsCache = {
    get() {
        let sceneFonts = window.localStorage.sceneFonts || []
        if (sceneFonts && sceneFonts.length) {
            sceneFonts = JSON.parse(sceneFonts)
        }

        return sceneFonts
    },
    getSceneFonts(sceneId) {
        let scenesFonts = this.get()
        let sceneFonts = _.find(scenesFonts, ['id', sceneId])
        return sceneFonts ? sceneFonts.fonts : []
    },
    updateSceneFonts(sceneId, fonts) {
        let sceneFonts = this.get()

        sceneFonts = _.reject(sceneFonts, {'id': sceneId})
        if (fonts.length) {
            sceneFonts.push({
                id: sceneId,
                fonts: fonts
            })
        }

        window.localStorage.sceneFonts = JSON.stringify(sceneFonts)
    },
    clearSceneFonts(sceneId) {
        let sceneFonts = this.get()

        sceneFonts = _.reject(sceneFonts, {'id': sceneId})
        window.localStorage.sceneFonts = JSON.stringify(sceneFonts)
    },
}


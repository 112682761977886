import _ from 'lodash'
import getMediaType from "@/helper/getMediaType";
import {BrandLayerTypes, MediaType} from "@/helper/consts";

const brandsHelper = {
  getNodeBrandValue(node, brandKit) {
    let brandObj = {};
    const brandLayers = _.get(brandKit, 'layres', []);

    const Nodetags = _.map(node.tags, (tag)=>{
      return {name: tag, value: null}
    });

    let brandedNode = {
      placeholder: null,
      tags: Nodetags
    };

    brandLayers.forEach(brandLayer => {
      if (_.isEmpty(brandLayer)) {
        return;
      }

      if (node.media_type === MediaType.TEXT && brandLayer.type !== BrandLayerTypes.TEXT) {
        return;
      }

      if (node.media_type !== MediaType.TEXT && brandLayer.type !== BrandLayerTypes.MEDIA) {
        return;
      }

      const reg = this.layerRegex(brandLayer);

      // ADD TAGS
      node.tags.forEach(tag => {
        if (tag === brandLayer.name.toLowerCase()) {
          const index = _.findIndex(brandedNode.tags, { name: brandLayer.name.toLowerCase() });
          if (index !== -1) {
            brandedNode.tags[index] = _.assign({}, brandedNode.tags[index], { value: brandLayer });
          }
        }
      });

      if (reg.test(node.name)) {
        brandedNode.placeholder = brandLayer;
      }
    });

    const lastTagWithBrand = _.last(_.filter(brandedNode.tags, tag => tag.value !== null));
    const brandLayer = lastTagWithBrand !== undefined ?  lastTagWithBrand.value : brandedNode.placeholder;
    if (brandLayer) {
      brandLayer.values.forEach(objValue => {
        _.forOwn(objValue, (value, key) => {

          if (brandLayer.type === BrandLayerTypes.TEXT) {
            const filteredVal = _.map(node.val, (nodeVal) => {
              return _.omit(nodeVal, ['font_path', 'font_checksum'])
            })

            brandObj.asset_path = value;
            brandObj.val = filteredVal;
          } else if ((brandLayer.type === BrandLayerTypes.MEDIA)) {
            const mediaType = brandsHelper.isColor(value)
              ? 'color'
              : getMediaType(value);

            brandObj.media_type = mediaType;
            brandObj.val = mediaType === 'color' ? brandsHelper.getRgbColor(value) : value;
          }
        });
      });
    }

    return brandObj;
  },

  layerRegex(layer) {
    let regArr = []
    let placeHolderNames = _.get(layer, 'placeHolderNames', []);
    placeHolderNames.forEach(p => {
      regArr.push('(^' + p.replaceAll('%', '.*') + '$)')
    })

    return new RegExp(regArr.join('|'));
  },

  isColor(color) {
    const style = new Option().style;
    style.color = color;

    return style.color !== '';
  },

  getRgbColor(color) {
    const style = new Option().style;
    style.color = color;

    return style.color ? style.color : color;
  },
}

export default brandsHelper
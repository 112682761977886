<template>
  <div v-if="scene">
    <div v-if="!nodes.length && !selectedNode">
      <h3>Placeholders absent! Choose another filter</h3>
    </div>

    <div v-if="selectedNode" class="editorHeader">
      <IdmButton
        :btnType="'small edit'"
        @click="selectNode()"
        data-test="node-list-select-node-button"
      >
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 9L1 5L5 1"
            stroke="#07354A"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </IdmButton>
      <span class="subtitle">All placeholders</span>

      <div class="layers">
        <IdmButton
          :btnType="'small edit'"
          :disabled="currentIndex === 0"
          @click="previousNode"
          data-test="node-list-previous-node-button"
        >
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9L1 5L5 1"
              stroke="#07354A"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </IdmButton>
        <IdmButton
          :btnType="'small edit'"
          :disabled="currentIndex === totalNodes - 1"
          @click="nextNode"
          data-test="node-list-next-node-button"
          ><svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 9L5 5L1 1"
              stroke="#07354A"
              stroke-linecap="round"
              stroke-linejoin="round"
            /></svg
        ></IdmButton>
      </div>
    </div>

    <div
      v-for="(node, nodeIndex) in nodes"
      v-show="!selectedNode || node == selectedNode"
      class="node"
      :ref="'node-' + nodeIndex"
      :key="nodeIndex"
      :class="{
        selected: node == selectedNode,
        hover: node == hoveredNode,
        hoverOnCanvas: hoveredOnCanvas,
        hidden: node.is_hidden && !node.is_trackmatte,
        renaming: node == toRenamingNode,
      }"
      @click="selectNodeAndChangeTime(node)"
      @mouseover="hoverNode({ node })"
      @mouseleave="hoverNode()"
      :data-test="`node-list-node-${node.key.split(' ').join('-')}`"
    >
      <div class="preview-wrap" :class="node.media_type">
        <div
          v-if="node.media_type === 'color'"
          class="preview"
          :style="{ backgroundColor: node.val }"
        ></div>
        <div
          v-if="node.media_type === 'image'"
          class="preview"
          :style="{
            backgroundImage: node.edited
              ? `url(${node.val})`
              : `url(https://sozzpnghef.execute-api.us-east-1.amazonaws.com/file?vpath=${node.val})`,
          }"
        ></div>
      </div>

      <div class="node-content">
        <div class="node-wrap" style="flex-grow: 1">
          <IdmHoverText :class-name="'node-name-tooltip'" :filter="filterTitle">
            <div
              v-show="node !== toRenamingNode"
              :ref="'nodeName' + nodeIndex"
              class="node-name"
              :title="titleValue('nodeName' + nodeIndex, node.name)"
            >
              {{ node.name }}
            </div>
          </IdmHoverText>
        </div>

        <div v-show="node == toRenamingNode" class="node-name">
          <input
            class="editing"
            :ref="'input' + node.key"
            type="text"
            v-model="node.name"
            @change="nodeNameChange(node, $event.target.value)"
            @focusout="toRenamingNode = null"
          />
        </div>

        <div class="node-status">
          <div v-show="node.is_hidden && !node.is_trackmatte" class="hidden"></div>
          <div v-show="node.edited || node.renamed" class="edited"></div>
        </div>

        <div class="node-time">
          <span class="start-time">{{ startTime(node) }}</span
          >-<span class="end-time">{{ endTime(node) }}</span>
        </div>
      </div>

      <div
        class="dots"
        :class="{ reverse: reversePopupMenu }"
        @mouseenter="subMenuEnter(nodeIndex)"
        @mouseleave="subMenuLeave"
        :data-test="`node-list-node-${node.key}-dots-menu`"
      >
        <div class="dropdown-actions">
          <!-- <div class="action" :class="{disabled : node == toRenamingNode}" @click.stop="renameNode(node)">Rename placeholder</div> -->

          <div
            v-if="!node.is_hidden && !node.is_trackmatte"
            class="action"
            @click.stop="hideNodeEvent(node)"
            :data-test="`node-list-node-${node.key}-dots-menu-hide-button`"
          >
            Hide placeholder
          </div>
          <div
            v-if="node.is_hidden && !node.is_trackmatte"
            class="action"
            @click.stop="showNodeEvent(node)"
            :data-test="`node-list-node-${node.key}-dots-menu-show-button`"
          >
            Show placeholder
          </div>

          <div
            v-if="node.edited || node.renamed"
            class="action"
            @click.stop="clearNodeChanges(node)"
            :data-test="`node-list-node-${node.key}-dots-menu-clear-changes-button`"
          >
            Clear changes
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as timeHelper from '@/helper/time';
import { Filter, MediaType, Sort } from '@/helper/consts';
import { mapActions } from 'vuex';
import sceneCacheStorage from '@/helper/cache';

export default {
  name: 'NodesList',

  props: {
    filter: {
      type: String,
      required: true,
      default: Filter.ALL,
    },
    sort: {
      type: String,
      required: true,
      default: Sort.TIMELINE,
    },
  },

  data: () => ({
    reversePopupMenu: false,
    toRenamingNode: null,
  }),

  computed: {
    currentIndex() {
      return this.selectedNode
        ? _.findIndex(this.nodes, { key: this.selectedNode.key })
        : 0;
    },
    totalNodes() {
      return this.nodes.length;
    },
    scene() {
      return this.$store.getters['builder/scene'];
    },
    selectedNode() {
      return this.$store.getters['builder/selectedNode'];
    },
    hoveredNode() {
      return this.$store.getters['builder/hoveredNode'];
    },
    hoveredOnCanvas() {
      return this.$store.getters['builder/hoveredOnCanvas'];
    },
    filteredScenes() {
      let res = [];

      switch (this.filter) {
        case Filter.ALL:
          res = this.scene.nodes;
          break;
        case Filter.MEDIA:
          res = _.filter(this.scene.nodes, (node) =>
            [MediaType.VIDEO, MediaType.IMAGE, MediaType.COLOR].includes(node.media_type)
          );
          break;
        default:
          res = _.filter(
            this.scene.nodes,
            (node) => node.media_type === this.filter.toLowerCase()
          );
      }

      if (
        this.selectedNode &&
        _.find(res, { key: this.selectedNode.key }) === undefined
      ) {
        res.push(this.selectedNode);
      }

      return res;
    },
    sortedScenes() {
      // TODO: implement sorting by 'Edited items' ASC/DESC
      switch (this.sort) {
        case Sort.TIMELINE:
          return _.orderBy(
            this.filteredScenes,
            ['custom_start_time', 'duration_in_seconds', 'key'],
            ['asc', 'asc', 'asc']
          );
        case Sort.ALPHABET:
          return _.orderBy(this.filteredScenes, ['key'], ['asc']);
        case Sort.EDITED_ASC:
          return _.orderBy(
            this.filteredScenes,
            ['edited', 'renamed', 'name'],
            ['desc', 'desc', 'asc']
          );
        case Sort.EDITED_DESC:
          return _.orderBy(
            this.filteredScenes,
            ['edited', 'renamed', 'name'],
            ['asc', 'asc', 'asc']
          );
        default:
          return this.filteredScenes;
      }
    },
    nodes() {
      return this.sortedScenes;
    },
  },

  emits: ['changeTime'],

  methods: {
    ...mapActions('builder', [
      'hoverNode',
      'selectNode',
      'showNode',
      'hideNode',
      'undoNodeChanges',
    ]),
    subMenuEnter(nodeIndex) {
      const el = this.$refs['node-' + nodeIndex];
      if (!el) {
        return;
      }

      const space = window.innerHeight - el[0].getBoundingClientRect().bottom;
      this.reversePopupMenu = space <= 61;
    },
    subMenuLeave() {
      this.reversePopupMenu = false;
    },
    filterTitle(e) {
      return e?.scrollHeight > e?.clientHeight + 1;
    },
    titleValue(refNodeName, title) {
      const nodeEl = this.$refs[refNodeName];
      if (!nodeEl) {
        return '';
      }
      const filterTitleRes = this.filterTitle(nodeEl[0]);

      return filterTitleRes ? title : '';
    },
    previousNode() {
      this.selectNode(this.nodes[this.currentIndex - 1]);
    },
    nextNode() {
      this.selectNode(this.nodes[this.currentIndex + 1]);
    },
    selectNodeAndChangeTime(node) {
      this.selectNode(node);
      this.$emit('changeTime', node);
    },
    startTime(node) {
      return timeHelper.getStartTimeFormatted(node.custom_start_time);
    },
    endTime(node) {
      return timeHelper.getEndTimeFormatted(
        node.custom_start_time,
        node.duration_in_seconds
      );
    },
    nodeNameChange(node, value) {
      let name = value.substring(0, 100).trim();
      node.name = name.replace(/(\r\n|\n|\r)/gm, '');
      this.nodeChanged(node, name);

      sceneCacheStorage.updateSceneCache(this.scene);
    },
    renameNode(node) {
      this.toRenamingNode = node;
      this.$nextTick(() => {
        this.$refs['input' + node.key][0].focus();
      });
    },
    nodeChanged(node, newNodeName) {
      this.toRenamingNode = null;
    },
    showNodeEvent(node) {
      this.showNode(node);
      this.$emit('preview');
    },
    hideNodeEvent(node) {
      this.hideNode(node);
      this.$emit('preview');
    },
    clearNodeChanges(node) {
      this.toRenamingNode = null;
      this.hoverNode();
      this.undoNodeChanges(node);
      this.$emit('preview');
    },
  },
};
</script>

<style lang="scss">
.k-animation-container.k-animation-container-fixed.k-animation-container-shown.node-name-tooltip {
  margin-top: -6px;
  left: 61px !important;
  max-width: 220px;

  .k-tooltip {
    margin-top: 0;
  }
}
</style>

<style lang="scss" scoped>
.editorHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .idm-btn {
    width: 24px;
    min-width: 24px;
    overflow: hidden;
    border-radius: 4px 0 0 4px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-500);
    line-height: 17px;
    margin-left: 6px;
  }

  .layers {
    display: flex;
    margin-left: auto;

    .idm-btn {
      border-radius: 4px 0 0 4px;

      &:last-child {
        border-radius: 0 4px 4px 0;
        margin-left: 2px;
      }
    }
  }
}

.node {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 51px;
  padding: 13px 0 12px 6px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;

  &:not(:first-child) {
    margin-top: -1px;
  }

  &.hover .preview-wrap {
    &.text,
    &.audio {
      background-color: #fafafa;
    }
  }

  &.hover,
  &.renaming {
    background-color: #def3fe;
    cursor: pointer;
  }

  &.hover:not(.hoverOnCanvas),
  &.renaming {
    .dots {
      opacity: 1;
    }
  }

  &.selected {
    background-color: #e2e9ed;
    border-top: 1px solid #07354a;
    border-bottom: 1px solid #07354a;

    & + .node {
      border-top: 1px solid #07354a;
    }

    .preview-wrap.audio,
    .preview-wrap.text,
    .preview-wrap.image {
      background-color: #fafafa;
    }

    .dots {
      opacity: 1;
    }
  }

  &.hidden {
    background-color: #eeeeee;

    .node-content {
      color: #acbcc3;
    }
  }

  &.hidden.hover {
    background-color: #eef9ff;
  }

  .preview-wrap {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-color: #e2e9ed;
    border-radius: 4px;

    &.image,
    &.color {
      height: 13px;
      border-radius: 0;
    }

    &.image .preview {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.audio {
      background-image: url(~@/assets/icons/audio.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &.text {
      background-image: url(~@/assets/icons/text.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    .preview {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }

  .node-content {
    color: #07354a;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .node-name {
    margin-right: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    user-select: none;
    max-width: 170px;
    flex-grow: 1;
    word-break: break-all;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    .editing {
      width: 100%;
      padding: 0 3px;
      background-color: #ffffff;
      border: 1px solid #62d9ff;
      border-radius: 4px;
      font-size: 16px;
      outline: none;
    }
  }

  .node-status {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 4px;
    gap: 7px;

    .hidden {
      width: 17px;
      height: 8px;
      background-image: url(~@/assets/icons/hidden.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    .edited {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: linear-gradient(90deg, #3dbcff 0%, #1a6dff 100%);
    }
  }

  .node-time {
    font-variant-numeric: tabular-nums;
    user-select: none;
    flex-shrink: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}

.dots {
  position: relative;
  width: 3px;
  height: 15px;
  padding: 0 10px 0 5px;
  margin-left: auto;
  opacity: 0;
  display: flex;
  justify-content: center;
  background-image: url(~@/assets/icons/menu_dots.svg);
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: '';
    display: none;
    position: absolute;
    bottom: -10px;
    right: 0;
    height: 30px;
    width: 50px;
  }

  &.reverse {
    &::before {
      top: -10px;
      bottom: unset;
    }

    &:hover {
      .dropdown-actions {
        top: unset;
        bottom: 20px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-image: url(~@/assets/icons/menu_dots_active.svg);

    &::before {
      display: block;
    }

    .dropdown-actions {
      display: block;
    }
  }
}

.dropdown-actions {
  z-index: 1;
  display: none;
  font-size: 16px;
  color: #07354a;
  background: #fafafa;
  box-shadow: 0 5px 20px rgba(7, 53, 74, 0.4);
  border-radius: 8px;
  position: absolute;
  top: 20px;
  right: 5px;
  overflow: hidden;

  &::after {
    content: '';
    background: transparent;
    height: 20px;
    width: inherit;
    position: absolute;
    right: 0;
    top: -10px;
  }

  .action {
    user-select: none;
    color: #07354a;
    font-size: 16px;
    font-weight: 400;
    background: #fafafa;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 68px;
    padding-bottom: 3px;

    &:hover {
      background-color: #def3fe;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}
</style>

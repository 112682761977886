<template>
  <div class="canvas_wrap">
    <canvas id="canvas" ref="canvas" class="hidden" data-test="editor-canvas"></canvas>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { MediaType } from '@/helper/consts';

export default {
  name: 'EditorCanvas',

  props: ['size','frame','scene'],

  data() {
    return {
      placeholders: [],
      ctx: null,
      padding: 10,
      timeDebug: false,
    }
  },

  computed: {
    nodesWithPlaceholdersOnThisFrame() {
      return this.scene.nodes.filter(n => n.placeholder && n.placeholder.points[this.frame])
    },
    selectedNode(){
      return this.$store.getters['builder/selectedNode']
    },
    hoveredNode(){
      return this.$store.getters['builder/hoveredNode']
    },
  },

  methods: {
    ...mapActions('builder', [
      'hoverNode',
      'selectNode',
    ]),
    onMousemove(event){
      if (this.timeDebug) console.time("onMousemove");

      const hovered = this.findIntersectedPlaceholder(event);
      this.hoverNode({
        node: hovered ? hovered[0] : null,
        onCanvas: true,
      })
      if (this.timeDebug) console.timeEnd("onMousemove");
    },
    onMouseleave(){
      if (this.timeDebug) console.time("onMouseleave");
      this.hoverNode(null)
      if (this.timeDebug) console.timeEnd("onMouseleave");
    },
    onClick(event){
      if (this.timeDebug) console.time("onClick");

      const selected = this.findIntersectedPlaceholder(event);
      this.selectNode(selected ? selected[0] : null)

      if (this.timeDebug) console.timeEnd("onClick");
    },
    calculateArea(points) {
      const pointsLength = points.length;
      let area = 0;

      for (let i = 0; i < pointsLength; i++) {
        const current = points[i];
        const next = points[(i + 1) % pointsLength];
        area += current[0] * next[1] - next[0] * current[1];
      }

      return Math.abs(area / 2);
    },
    findIntersectedPlaceholder(event) {
      let intersection = null;
      let smallestArea = Infinity;

      this.placeholders.forEach((p) => {
        if(this.ctx.isPointInPath(p[1], event.offsetX, event.offsetY)){
          const area = p[0].area;
          if (area < smallestArea) {
            smallestArea = area;
            intersection = p;
          }

          if (area === smallestArea) {
            intersection = [MediaType.VIDEO, MediaType.IMAGE, MediaType.COLOR].includes(p[0].media_type)
                ? p
                : intersection
          }
        }
      })
      
      return intersection;
    },
    createDot(x,y,angle){
      this.ctx.translate( x, y );
      this.ctx.rotate( angle );

      let dot = new Path2D();
      dot.rect(-4, -4, 8, 8);

      this.ctx.stroke(dot);
      this.ctx.fill(dot);

      this.ctx.rotate( -angle );
      this.ctx.translate( -x, -y );
      return dot
    },
    redrawCanvas(){
      if (this.timeDebug) console.time("redrawCanvas");
      let w = this.size.width + this.padding * 2
      let h = this.size.height + this.padding * 2

      this.$refs.canvas.style.width = `${w}px`
      this.$refs.canvas.style.height = `${h}px`

      this.$refs.canvas.width = w
      this.$refs.canvas.height = h

      this.placeholders = []

      const matrixOfOpposites = {0:2,1:3,2:0,3:1}

      this.$nextTick(()=>{
        const canvas = this.$refs.canvas
        const ctx = canvas.getContext('2d')
        const w = canvas.width - this.padding * 2
        const h = canvas.height - this.padding * 2

        this.nodesWithPlaceholdersOnThisFrame.forEach((node) => {

          const P = node.placeholder.points[this.frame]
          const POINTS_original = []
          const POINTS = []

          P.forEach((_,index)=>{
            if(index % 2 == 0){
              const current = [
                P[index] * w + this.padding,
                P[index+1] * h + this.padding 
              ]
              const next = [
                (P[index+2] || P[0]) * w + this.padding,
                (P[index+3] || P[1]) * h + this.padding
              ]
              const middleOfSegment = [
                (next[0] + current[0]) / 2,
                (next[1] + current[1]) / 2,
              ]

              POINTS_original.push(current)
              POINTS.push(current)
              POINTS.push(middleOfSegment)
            }
          })

          /******draw main border line******/

          let numPoints = POINTS.length
          const placeholder = new Path2D();

          placeholder.moveTo(POINTS[0][0], POINTS[0][1])
          POINTS.forEach(p=>{
            placeholder.lineTo(p[0], p[1])
          })
          placeholder.lineTo(POINTS[0][0], POINTS[0][1])

          if(node == this.hoveredNode || node == this.selectedNode){

            ctx.strokeStyle = "#1A6DFF"
            ctx.lineWidth = 1

            ctx.stroke(placeholder);

            /******determining the coordinates for inner and outer border******/

            const POINTS_internal = []
            const POINTS_external = []

            POINTS_original.forEach((p,i) => {
              const variants = [
                [p[0] - 1, p[1] - 1],
                [p[0] + 1, p[1] - 1],
                [p[0] + 1, p[1] + 1],
                [p[0] - 1, p[1] + 1],
              ]

              variants.find((p,i) => {
                if(ctx.isPointInPath(placeholder, p[0], p[1])){
                  POINTS_internal.push(p)
                  POINTS_external.push(variants[matrixOfOpposites[i]])
                  return true
                }
                return false
              })
            })

            /******internal border line draw******/

            const placeholder_internal = new Path2D();

            placeholder_internal.moveTo(POINTS_internal[0][0], POINTS_internal[0][1])
            POINTS_internal.forEach(p=>{
              placeholder_internal.lineTo(p[0], p[1])
            })
            placeholder_internal.lineTo(POINTS_internal[0][0], POINTS_internal[0][1])

            ctx.stroke(placeholder_internal);

            /******external border line draw******/

            const placeholder_external = new Path2D();

            placeholder_external.moveTo(POINTS_external[0][0], POINTS_external[0][1])
            POINTS_external.forEach(p=>{
              placeholder_external.lineTo(p[0], p[1])
            })
            placeholder_external.lineTo(POINTS_external[0][0], POINTS_external[0][1])

            ctx.strokeStyle = "#E6F6FE"
            if(node == this.hoveredNode && this.selectedNode && this.selectedNode != node){
              ctx.strokeStyle = "#62D9FF"
            }
            ctx.stroke(placeholder_external);
          }

          /******points drawing******/

          if(node == this.hoveredNode || node == this.selectedNode){
            ctx.strokeStyle = "#1A6DFF"
            ctx.fillStyle = "#E6F6FE"
            if(node == this.hoveredNode && this.selectedNode && this.selectedNode != node){
              ctx.fillStyle = "#62D9FF"
            }
          
            const angle = this.getAngle(
              [POINTS[0][0],POINTS[0][1]],
              [POINTS[1][0],POINTS[1][1]],
              [POINTS[1][0],POINTS[0][1]]
            )

            POINTS.forEach(p=>{
              this.createDot(p[0], p[1], angle)
            })
          }

          node.area = this.calculateArea(POINTS_original);

          this.placeholders.push([node, placeholder])
        })
        if (this.timeDebug) console.timeEnd("redrawCanvas");
      })
    },
    getAngle(A,B,C) {
      /*
      vector1 = segment from point A to point B
      vector2 = segment from point A to point C
      To find the cosine of the angle between the vectors,
      need to divide the scalar product of these vectors by the product of their lengths
      */
      const v1 = {x: B[0] - A[0], y: B[1] - A[1]}
      const v2 = {x: C[0] - A[0], y: C[1] - A[1]}
      const dividend = v1.x * v2.x + v1.y * v2.y;
      const divisor = Math.sqrt(Math.pow(v1.x,2) + Math.pow(v1.y,2)) * Math.sqrt(Math.pow(v2.x,2) + Math.pow(v2.y,2));
      const cosine = dividend / (divisor || 1)
      return Math.acos(cosine) * (A[1]<B[1] ? 1 : -1)
    },
  },

  watch: {
    frame() {
      this.redrawCanvas()
    },
    hoveredNode() {
      this.redrawCanvas()
    },
    selectedNode() {
      this.redrawCanvas()
    },
  },

  mounted() {
    this.redrawCanvas()
    this.$refs.canvas.addEventListener('mousemove', this.onMousemove)
    this.$refs.canvas.addEventListener('mouseleave', this.onMouseleave)
    this.$refs.canvas.addEventListener('click', this.onClick)
    this.ctx = this.$refs.canvas.getContext('2d')
  },

  beforeDestroy () {
    this.$refs.canvas.removeEventListener('mousemove', this.onMousemove)
    this.$refs.canvas.removeEventListener('mouseleave', this.onMouseleave)
    this.$refs.canvas.removeEventListener('click', this.onClick)
  }
}
</script>

<style scoped>
  .canvas_wrap{
    display: flex;
    justify-content: center;
  }
  #canvas{
    position: absolute;
    top: -10px;
    margin: 0 auto;
    z-index: 10;
  }
</style>

import {createStore} from 'vuex'
import builder from './modules/builder'
import errors from './modules/errors'

export const store = createStore({
    state: {
        test: null,
    },

    mutations: {
        changeTest(state, val) {
            state.test = val
        },
    },

    getters: {
        test(state) {
            return state.test;
        },
    },

    modules: {
        builder,
        errors
    }
})
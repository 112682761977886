import _ from "lodash";

const sceneCacheStorage = {
    get() {
        let editedScenes = window.localStorage.editedScenes || []
        if (editedScenes && editedScenes.length) {
            editedScenes = JSON.parse(editedScenes)
        }
        
        return editedScenes
    },

    getSceneCache(sceneId) {
        let editedScenes = this.get()

        return _.find(editedScenes, ['id', parseInt(sceneId)])
    },

    getScenePreview(sceneId) {
        const editedSceneCache = this.getSceneCache(sceneId)
        return _.get(editedSceneCache, ['preview'])
    },

    previewExist(sceneId) {
        const scenePreview = this.getScenePreview(sceneId)
        return scenePreview !== undefined && scenePreview !== null
    },

    cacheExist(sceneId) {
        return this.getSceneCache(sceneId) !== undefined
    },

    updateBrandId(scene, brandId) {
        let editedScenes = this.get();
        const editedScene = _.find(editedScenes, ['id', parseInt(scene.id)]);
        editedScenes = _.reject(editedScenes, {'id': scene.id});

        if (editedScene) {
            editedScene.brandId = brandId;
            editedScenes.push(editedScene);
        } else {
            editedScenes.push({
                id: scene.id,
                last_modified: scene.last_modified,
                description: scene.description,
                tags: scene.tags,
                nodes: [],
                preview: null,
                brandId: brandId,
            })
        }

        window.localStorage.editedScenes = JSON.stringify(editedScenes);
    },

    updateSceneCache(scene, preview = null) {
        let editedScenes = this.get()

        const editedScene = _.find(editedScenes, ['id', parseInt(scene.id)])
        editedScenes = _.reject(editedScenes, {'id': scene.id})
        if (scene.edited || scene.renamed) {
            const editedNodes = _.filter(scene.nodes, function (node) {
                    return node.edited || node.renamed
                }
            )
            const nodes = _.map(editedNodes, (node) => {
                return _.omit(node, ['placeholder'])
            })
            editedScenes.push({
                id: scene.id,
                last_modified: scene.last_modified,
                description: scene.description,
                tags: scene.tags,
                nodes: nodes,
                preview: preview ? preview : _.get(editedScene, ['preview'], null),
                brandId: _.get(editedScene, ['brandId'], null),
            })
        }
        window.localStorage.editedScenes = JSON.stringify(editedScenes)
    },

    clearSceneCache(sceneId) {
        let editedScenes = this.get()

        editedScenes = _.reject(editedScenes, {'id': sceneId})
        window.localStorage.editedScenes = JSON.stringify(editedScenes)
    },

    clearNodeCache(sceneId, nodeKey) {
        let editedScenes = this.get()
        let scene = _.find(editedScenes, {id: sceneId})
        editedScenes = _.reject(editedScenes, {'id': sceneId})

        if (scene && scene.nodes) {
            let node = _.find(scene.nodes, {'key': nodeKey})
            let editedNodes = _.reject(scene.nodes, {'key': nodeKey})
            if (editedNodes.length) {
                editedScenes.push(scene)
            }
        }

        window.localStorage.editedScenes = JSON.stringify(editedScenes)
    }
}

export default sceneCacheStorage
<template>
  <div class="editor-media">
    <div class="editor-title">
      <p class="editor-text">Replace asset</p>
      <IdmButton
        :btnType="'small outline'"
        :disabled="!node.edited"
        :text="'Clear changes'"
        @click="clearAllChanges"
        data-test="media-editor-clear-changes-button"
      ></IdmButton>
    </div>

    <div class="radio-wrapper">
      <label
        class="radio-label"
        :class="{ disabled: previewLoading }"
        data-test="media-editor-clear-changes-button"
      >
        <IdmRadio
          :name="'assetType'"
          :value="'color'"
          :checked="assetType === 'color'"
          @check-radio="changeAssetType"
          :disabled="previewLoading"
          data-test="media-editor-asset-type-color-radio"
        />
        <span class="text">Color</span>
      </label>
      <label class="radio-label" :class="{ disabled: previewLoading }">
        <IdmRadio
          :name="'assetType'"
          :value="'media'"
          :checked="assetType === 'media'"
          @check-radio="changeAssetType"
          :disabled="previewLoading"
          data-test="media-editor-asset-type-media-radio"
        />
        <span class="text">Media</span>
      </label>
    </div>
    <keep-alive>
      <div class="color-picker-wrapper" v-if="assetType === 'color'">
        <button
          class="color-picker-btn"
          @click.stop="toggleColorPicker"
          data-test="media-editor-colorpicker-toggle-button"
        >
          <svg
            class="rect"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="15"
              height="15"
              rx="1.5"
              :fill="colorPickerBtnRectFill"
              stroke="#DBDBDB"
            ></rect>
          </svg>
        </button>
        <IdmColorPicker
          ref="cpWrapper"
          class="color-picker"
          :class="{ hidden: !colorPickerIsActive }"
          :alphaVisible="false"
          :hex="hex"
          @choose-color="handleColorPicker"
          @colorChanged="handleColorChange"
          data-test="media-editor-colorpicker"
        />
      </div>
      <DropZone
        class="uploader"
        :progress-total="total"
        :progress-loaded="loaded"
        :fileName="node.fileName"
        :show-delete="node.edited"
        @file-drop="handleFileDrop"
        @clear-changes="clearAllChanges"
        data-test="media-editor-dropzone"
        v-else
      ></DropZone>
    </keep-alive>

    <div
      class="media-properties-wrapper"
      v-show="assetType === 'media'"
      v-if="showMediaProperties"
    >
      <h5 class="title">Change appearance</h5>

      <div class="media-properties-section">
        <h5 class="section-title">Fit options</h5>

        <div class="fit-options-wrapper">
          <div
            class="fit-option-wrapper"
            :class="{ checked: fitOption === 'fit' }"
            @click="
              fitOption = 'fit';
              setMediaProperties();
            "
            data-test="media-editor-fit-option"
          >
            <div class="fit-preview fit"></div>
            <p class="fit-title">Fit</p>
            <IdmRadio
              :name="'fitOption'"
              :value="'fit'"
              :checked="fitOption === 'fit'"
              @check-radio="setFitOption"
            />
          </div>

          <div
            class="fit-option-wrapper"
            :class="{ checked: fitOption === 'fill' }"
            @click="
              fitOption = 'fill';
              setMediaProperties();
            "
            data-test="media-editor-fill-option"
          >
            <div class="fit-preview fill"></div>
            <p class="fit-title">Fill</p>
            <IdmRadio
              :name="'fitOption'"
              :value="'fill'"
              :checked="fitOption === 'fill'"
              @check-radio="setFitOption"
            />
          </div>

          <div
            class="fit-option-wrapper"
            :class="{ checked: fitOption === 'custom' }"
            @click="
              fitOption = 'custom';
              setMediaProperties();
            "
            data-test="media-editor-original-option"
          >
            <div class="fit-preview original"></div>
            <p class="fit-title">Original</p>
            <IdmRadio
              :name="'fitOption'"
              :value="'custom'"
              :checked="fitOption === 'custom'"
              @check-radio="setFitOption"
            />
          </div>
        </div>
      </div>

      <div class="media-properties-section">
        <h5 class="section-title">Choose alignment</h5>

        <div class="choose-alignment-wrapper">
          <div class="alignment-options">
            <div
              v-for="(option, index) in alignmentOptions"
              :key="index"
              class="alignment-option"
              :class="{
                checked: alignmentOption.x === option.x && alignmentOption.y === option.y,
              }"
              @click="setAlignment(option)"
              :data-test="`media-editor-alignment-option-${option.name}`"
            ></div>
          </div>

          <p class="alignment-name">{{ alignmentOption.name ?? 'Center' }} alignment</p>
        </div>
      </div>

      <div class="media-properties-section video" v-if="showVideoBehaviour">
        <h5 class="section-title">Video behavior</h5>

        <p class="section-subtitle">When placeholder duration is longer than video</p>

        <div class="video-behavior-wrapper">
          <div class="video-option-wrapper">
            <IdmRadio
              :name="'videoOption'"
              :value="'cut'"
              :checked="videoOption === 'cut'"
              @check-radio="setVideoOption"
              data-test="media-editor-video-option-cut"
            />
            <p class="video-title">Cut</p>
          </div>
          <div class="video-option-wrapper">
            <IdmRadio
              :name="'videoOption'"
              :value="'loop'"
              :checked="videoOption === 'loop'"
              @check-radio="setVideoOption"
              data-test="media-editor-video-option-loop"
            />
            <p class="video-title">Loop</p>
          </div>
          <div class="video-option-wrapper">
            <IdmRadio
              :name="'videoOption'"
              :value="'hold'"
              :checked="videoOption === 'hold'"
              @check-radio="setVideoOption"
              data-test="media-editor-video-option-hold"
            />
            <p class="video-title">Hold last frame</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropZone from '@/components/DropZone';
import { useUploadToS3 } from '@/helper/uploadToS3Composable.js';
import getMediaType from '@/helper/getMediaType';
import { mapGetters, mapActions } from 'vuex';
import brandsHelper from '@/helper/brands';

export default {
  name: 'mediaEditor',
  components: {
    DropZone,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    assetType: 'color',
    loaded: null,
    total: null,
    colorPickerIsActive: false,
    hex: null,
    fitOption: 'fit',
    alignmentOptions: [
      { x: 0, y: 0, name: 'top-left' },
      { x: 0.5, y: 0, name: 'top-center' },
      { x: 1, y: 0, name: 'top-right' },
      { x: 0, y: 0.5, name: 'left' },
      { x: 0.5, y: 0.5, name: 'center' },
      { x: 1, y: 0.5, name: 'right' },
      { x: 0, y: 1, name: 'bottom-left' },
      { x: 0.5, y: 1, name: 'bottom-center' },
      { x: 1, y: 1, name: 'bottom-right' },
    ],
    alignmentOption: { x: 0.5, y: 0.5, name: 'center' },
    videoOption: 'cut',
  }),
  computed: {
    ...mapGetters('builder', ['previewLoading']),
    colorPickerBtnRectFill() {
      const rgb = this.node.val
        .slice(this.node.val.indexOf('(') + 1, this.node.val.indexOf(')'))
        .split(',');

      return `rgb(${Number(rgb[0])},${Number(rgb[1])},${Number(rgb[2])})`;
    },
    showMediaProperties() {
      if (this.assetType !== 'color' && brandsHelper.isColor(this.node.val)) {
        return false;
      }

      return this.node.fileName || this.node.val;
    },
    showVideoBehaviour() {
      return (
        (this.node.media_type !== 'image' && this.node.media_type !== 'color') ||
        (this.node.mediaLink && this.node.mediaLink.indexOf('.gif') !== -1) ||
        (this.node.val && this.node.val.indexOf('.gif') !== -1)
      );
    },
  },
  watch: {
    'node.media_type'(newVal) {
      this.assetType = this.node.media_type === 'color' ? 'color' : 'media';
    },
    'node.edited'(newVal) {
      newVal || (this.total = this.loaded = null);
      this.assetType = this.node.media_type === 'color' ? 'color' : 'media';
    },
    'node.val'(newVal) {
      if (this.assetType === 'color' && newVal !== this.colorPickerBtnRectFill) {
        this.setColorValueFromNode();
      } else if (
        this.assetType === 'media' &&
        this.node.media_type === 'image' &&
        newVal !== this.node.mediaLink
      ) {
        this.node.mediaLink = this.node.val;
      }
    },
  },
  created() {
    if (this.node.media_type !== 'color') {
      this.assetType = 'media';
    } else {
      this.setColorValueFromNode();
    }

    this.updateNodeValue({
      node: this.node,
      data: {
        val: this.node.val,
        media_type: this.node.media_type,
        color: {
          r:
            this.node.media_type === 'color'
              ? this.setColorValueFromNode().r
              : this.node.edited && this.node.color
              ? this.node.color.r
              : 172,
          g:
            this.node.media_type === 'color'
              ? this.setColorValueFromNode().g
              : this.node.edited && this.node.color
              ? this.node.color.g
              : 188,
          b:
            this.node.media_type === 'color'
              ? this.setColorValueFromNode().b
              : this.node.edited && this.node.color
              ? this.node.color.b
              : 195,
        },
        alignment_scale_type: this.node.alignment_scale_type,
        alignment_x_align: this.node.alignment_x_align,
        alignment_y_align: this.node.alignment_y_align,
        if_longer: this.node.if_longer,
      },
    });
    console.log('this.node: ', this.node);

    this.setAppearanceOptions();
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    console.log('this.fitOption: ', this.fitOption);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    ...mapActions('builder', ['updateNodeValue', 'undoNodeChanges']),
    setAppearanceOptions() {
      if (this.node.alignment_scale_type) this.fitOption = this.node.alignment_scale_type;

      if (
        typeof this.node.alignment_x_align !== 'undefined' &&
        typeof this.node.alignment_y_align !== 'undefined'
      )
        this.alignmentOption = {
          x: this.node.alignment_x_align,
          y: this.node.alignment_y_align,
        };

      if (typeof this.node.if_longer !== 'undefined')
        this.videoOption = this.node.if_longer;
    },
    clearAllChanges() {
      this.loaded = this.total = null;
      this.undoNodeChanges();
      if (this.assetType === 'color') {
        this.setColorValueFromNode();
      }
      this.setAppearanceOptions();
      this.$emit('preview');
    },
    handleFileDrop(file) {
      const { loaded, total, request } = useUploadToS3(
        file,
        process.env.VUE_APP_CUSTOM_CONTENT_URL,
        process.env.VUE_APP_MEDIA_UPLOAD_ENDPOINT
      );
      this.loaded = loaded;
      this.total = total;
      request.then((link) => {
        this.updateNodeValue({
          node: this.node,
          data: {
            val: link,
            media_type: getMediaType(file.name),
            fileName: file.name,
            mediaLink: link,
          },
        });
        this.setMediaProperties();
        this.$emit('preview');
      });
    },
    handleColorChange(e) {
      this.hex = e.hex;
    },
    handleColorPicker: _.debounce(function (e) {
      if (this.previewLoading) {
        return;
      }

      this.updateNodeValue({
        node: this.node,
        data: {
          val: `rgb(${e.r},${e.g},${e.b})`,
          media_type: 'color',
          color: e,
        },
      });
      this.$emit('preview');
    }, 1500),
    toggleColorPicker() {
      this.colorPickerIsActive = !this.colorPickerIsActive;
    },
    handleOutsideClick(e) {
      this.$nextTick(() => {
        if (!this.$refs.cpWrapper?.$el?.contains(e.target) && this.colorPickerIsActive) {
          this.colorPickerIsActive = false;
        }
      });
    },
    rgbToHex(r, g, b) {
      return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
    },
    setColorValueFromNode() {
      const rgb = this.node.val
        .slice(this.node.val.indexOf('(') + 1, this.node.val.indexOf(')'))
        .split(',');
      this.hex = this.rgbToHex(Number(rgb[0]), Number(rgb[1]), Number(rgb[2]));
      return { r: Number(rgb[0]), g: Number(rgb[1]), b: Number(rgb[2]) };
    },
    changeAssetType(option) {
      if (!option.status) {
        return;
      }

      this.assetType = option.value;

      if (
        (option.value === 'media' &&
          this.node.mediaLink &&
          this.node.val !== this.node.mediaLink) ||
        (option.value === 'color' &&
          this.node.val !== this.colorPickerBtnRectFill &&
          this.node.edited) ||
        (option.value === 'color' && this.node.edited && this.node.media_type === 'image')
      ) {
        this.updateNodeValue({
          node: this.node,
          data: {
            val:
              this.assetType === 'color'
                ? this.colorPickerBtnRectFill
                : this.node.mediaLink,
            media_type:
              this.assetType === 'color' ? 'color' : getMediaType(this.node.mediaLink),
          },
        });
        this.$emit('preview');
      }
    },
    setFitOption(option) {
      if (option.status) {
        this.fitOption = option.value;
        this.setMediaProperties();
      }
    },
    setAlignment(option) {
      this.alignmentOption = option;
      this.setMediaProperties();
    },
    setVideoOption(option) {
      if (option.status) {
        this.videoOption = option.value;
        this.setMediaProperties();
      }
    },
    setMediaProperties() {
      this.updateNodeValue({
        node: this.node,
        data: {
          alignment_scale_type: this.fitOption,
          alignment_x_align: this.alignmentOption.x,
          alignment_y_align: this.alignmentOption.y,
          if_longer: this.videoOption,
          val: this.node.val,
          media_type: this.node.media_type,
        },
      });
      this.$emit('preview');
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-media {
  margin-top: 14px;
}

.editor-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editor-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #07354a;
  margin-top: 0;
}
.radio-wrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 11px;
  .radio-label {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    .text {
      color: #07354a;
      margin-left: 8px;
    }
  }
}
.uploader,
.color-picker-wrapper {
  margin-top: 24px;
}

.color-picker-wrapper {
  .color-picker-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e2e9ed;
    border-radius: 4px;
    background: #fafafa;
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    &:hover,
    &.active {
      border-color: #1a6dff;
      background-color: #e6f6fe;
    }
    .rect {
      width: 16px;
      height: 16px;
      margin: auto;
    }
  }
  .color-picker {
    margin-top: 9px;
    background: #fafafa;
    box-shadow: 0px 5px 20px rgb(7 53 74 / 40%);
    border-radius: 8px;
    :deep() .row {
      padding: unset;
    }

    &.hidden {
      height: 0;
      overflow: auto;
      margin: 0;
      opacity: 0;
      padding: 0;
      pointer-events: none;
    }
  }
}

.media-properties-wrapper {
  border-top: 1px solid #dbdbdb;
  padding-top: 21px;
  margin-top: 24px;

  .title,
  .section-title {
    font-size: var(--p2);
    color: var(--dark-500);
  }

  .title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .section-title {
    font-weight: 400;
  }

  .section-subtitle {
    font-size: var(--p1);
    color: var(--dark-500);
    margin-top: 16px;
    margin-bottom: 17px;
  }

  .media-properties-section {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 24px 0;

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--natural-100);
    }

    &.video {
      flex-direction: column;
    }

    .fit-options-wrapper {
      width: 196px;
      display: flex;
      align-items: start;
      justify-content: space-between;

      .fit-option-wrapper {
        width: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 7px 0 8px;
        border-radius: 8px;
        cursor: pointer;

        &:nth-child(2) {
          .fit-preview {
            border: initial;
          }
        }

        &:hover {
          background-color: var(--primary-50);
        }

        &.checked {
          background-color: var(--primary-200);
        }

        .fit-preview {
          width: 40px;
          height: 22px;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='30' height='22' fill='%23DEF3FE'/%3E%3Cline x1='0.706745' y1='21.595' x2='29.7067' y2='0.595029' stroke='%23ACBCC3'/%3E%3Cline y1='-0.5' x2='35.805' y2='-0.5' transform='matrix(0.809942 0.58651 0.58651 -0.809942 1 0)' stroke='%23ACBCC3'/%3E%3Crect x='0.5' y='0.5' width='29' height='21' stroke='%231A6DFF'/%3E%3C/svg%3E");
          background-position: 50% 50%;
          background-repeat: no-repeat;
          border: 1px solid var(--dark-200);
          border-radius: 2px;

          &.fit {
            background-size: 30px 22px;
          }

          &.fill {
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='38' height='20' fill='white'/%3E%3Cline y1='-0.474359' x2='42.906' y2='-0.474359' transform='matrix(0.885656 -0.464342 0.483943 0.8751 1 21.4359)' stroke='%23ACBCC3' stroke-width='0.948718'/%3E%3Cline y1='-0.474359' x2='42.906' y2='-0.474359' transform='matrix(0.885656 0.464342 0.483943 -0.8751 1 0.564087)' stroke='%23ACBCC3' stroke-width='0.948718'/%3E%3Crect x='0.5' y='0.5' width='39' height='21' rx='1.5' stroke='%231A6DFF'/%3E%3C/svg%3E");
            background-size: 40px 22px;
          }

          &.original {
            background-size: 22px 16px;
          }
        }

        .fit-title {
          font-size: var(--p1);
          color: var(--dark-500);
          margin: 9px 0;
        }
      }
    }

    .choose-alignment-wrapper {
      width: 120px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .alignment-options {
        width: 100%;
        height: 67.5px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 1px;
        background-color: var(--dark-200);
        border-radius: 4px;
        border: 1px solid var(--dark-200);
        margin-bottom: 8px;

        .alignment-option {
          background-color: var(--natural-40);
          cursor: pointer;

          &:nth-child(1) {
            border-top-left-radius: 4px;
          }
          &:nth-child(3) {
            border-top-right-radius: 4px;
          }
          &:nth-child(7) {
            border-bottom-left-radius: 4px;
          }
          &:nth-child(9) {
            border-bottom-right-radius: 4px;
          }

          &:hover {
            background-color: var(--primary-50);
          }

          &.checked {
            background-color: var(--primary-200);
            outline: 1px solid var(--primary-600);
          }
        }
      }

      .alignment-name {
        font-size: var(--p1);
        color: var(--dark-500);
        text-align: center;
        white-space: nowrap;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .video-behavior-wrapper {
      display: flex;
      align-items: center;

      .video-option-wrapper {
        display: flex;
        align-items: center;
        margin-right: 27px;

        .video-title {
          font-size: var(--p1);
          color: inherit;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>

import axios from 'axios';
import _ from 'lodash'

export function fileAvailabilityCheck(url, params){

  const defaults = {
    timeout: 1000,
    interval: 500,
    tryCount: 25,
    tryNumber: 1,
    debug: false,
  }

  const setup = _.defaults(params, defaults);

  if(setup.debug) console.log('[fileAvailabilityCheck] started. url/setup:',url,setup);
  
  return new Promise((resolve)=>{
    axios.head(url,{timeout:setup.timeout})
      .then(d => {
        if(d.status == 200){
          if(setup.debug) console.log('[fileAvailabilityCheck] v1. status 200');
          return resolve(true)
        }else{
          if(setup.tryNumber >= setup.tryCount){
            if(setup.debug) console.log('[fileAvailabilityCheck] v3. status != 200. limit on the number of attempts');
            return resolve(false)
          }else{
            if(setup.debug) console.log('[fileAvailabilityCheck] v2. status != 200. a new check is planned');
            if(setup.debug) console.log('[fileAvailabilityCheck] timeout', _.isArray(setup.interval) ? (setup.interval[setup.tryNumber-1] || _.last(setup.interval)) : setup.interval);
            setTimeout(()=>{
              return resolve(fileAvailabilityCheck(url, {...setup, tryNumber: setup.tryNumber+1}))
            },_.isArray(setup.interval) ? (setup.interval[setup.tryNumber-1] || _.last(setup.interval)) : setup.interval)
          }
        }
      }).catch((e)=>{
        if(setup.tryNumber >= setup.tryCount){
          if(setup.debug) console.log('[fileAvailabilityCheck] e2. request error. limit on the number of attempts', e);
          return resolve(false)
        }else{
          if(setup.debug) console.log('[fileAvailabilityCheck] e1. request error. a new check is planned', e);
          if(setup.debug) console.log('[fileAvailabilityCheck] timeout', _.isArray(setup.interval) ? (setup.interval[setup.tryNumber-1] || _.last(setup.interval)) : setup.interval);
          setTimeout(()=>{
            return resolve(fileAvailabilityCheck(url, {...setup, tryNumber: setup.tryNumber+1}))
          },_.isArray(setup.interval) ? (setup.interval[setup.tryNumber-1] || _.last(setup.interval)) : setup.interval)
        }
      })
  })
}
<template>
  <div class="file-uploader">
    <input
      class="upload-input"
      type="file"
      accept=".jpg,.gif,.png,.mp4,.flv,.webm,.jet,.mov"
      ref="uploader"
      @change="uploadFile"
      data-test="file-uploader-input-file"
    />
    <IdmButton :btnType="'secondary'" :text="text" @click="openUploadDialog" data-test="file-uploader-upload-button"
      ><IdmIcon name="upload"
    /></IdmButton>
    <div class="file-name" v-if="file && !dropzonePart">
      <span class="content">{{ file.name }}</span>
      <div class="delete-wrapper" @click.stop="deleteFile" data-test="file-uploader-delete-button">
        <img src="@/assets/icons/delete.svg" alt="delete" />
      </div>
    </div>
  </div>
</template>

<script>
import getMediaType from '@/helper/getMediaType';

export default {
  name: 'FileUploader',
  props: {
    text: {
      type: String,
      default: 'Upload media',
    },
    dropzonePart: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    file: null,
  }),
  methods: {
    openUploadDialog() {
      this.$refs.uploader.click();
    },
    uploadFile() {
      if (this.$refs.uploader.files.length > 0) {
        const file = this.$refs.uploader.files[0];
        const mediaType = getMediaType(file.name);
        if (!mediaType) {
          this.$emit('wrong-format-file-upload');
          return;
        }
        this.file = file;
        this.$emit('file-uploaded', this.file);
      }
    },
    deleteFile() {
      this.file = null;
      this.$refs.uploader.files = null;
      this.$emit('clear-changes');
    },
  },
};
</script>

<style lang="scss" scoped>
.file-uploader {
  .upload-input {
    display: none;
  }
  .upload-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px 12px 7px;
    gap: 8px;
    background: #cdedfe;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
  .file-name {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #07354a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: unset;
    .content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-height: 22px;
    }
    .delete-wrapper {
      width: 32px;
      height: 32px;
      padding: 5.5px 10px;
      background: #e2e9ed;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="scene-container">
    <div v-if="showCloseScene" class="popup-bg">
      <IdmPopup
        :title="'Leave Page?'"
        :paragraphs="[
          'You are about to close',
          scene.display_name,
          'The changes you made may not be saved.',
        ]"
      >
        <IdmButton
          :btnType="'secondary'"
          :text="'Cancel'"
          @click="showCloseScene = false"
          data-test="scene-page-leave-popup-cancel-button"
        />
        <IdmButton
          :btnType="'diverse'"
          :text="'Leave'"
          @click="leaveScene"
          data-test="scene-page-leave-popup-leave-button"
        />
      </IdmPopup>
    </div>

    <div v-if="showFontUploadError" class="popup-bg">
      <IdmPopup :title="fontUploadErrorData.title" :paragraphs="fontUploadErrorData.text">
        <IdmButton
          :btnType="'secondary'"
          :text="'Close'"
          @click="showFontUploadError = false"
          data-test="scene-page-error-popup-close-button"
        />
      </IdmPopup>
    </div>

    <IdmLoader v-if="loading" style="height: calc(100vh - 56px)" />

    <topBar
      v-if="!loading"
      :scene="scene"
      :video="sceneVideo"
      :video-url="videoUrl"
      :gif-url="gifUrl"
      :library-id="libraryId"
      :duration="originalDuration"
      @preview="previewModifiedScene"
      @close="closeSceneEvent"
    ></topBar>

    <div v-show="!loading" class="scene-wrap">
      <div class="sidebar-section">
        <!-- <IdmLoader
          v-if="!isPlaceholdersJsonLoaded"
          style="height: calc(100vh - 155px)"
          :type="'light'"
        /> -->

        <nodeFilter
          v-if="!selectedNode"
          :filter="filter"
          :sort="sort"
          @filterEvent="filter = $event"
          @sortEvent="sort = $event.value"
        ></nodeFilter>

        <nodesList
          :filter="filter"
          :sort="sort"
          @changeTime="scrollTimeToSelectedNode"
          @preview="previewModifiedScene"
        />

        <!-- <span :key="count"> -->
        <editorController
          v-if="selectedNode"
          :selected-node="selectedNode"
          :scene-id="scene.id"
          @preview="previewModifiedScene"
          @show-error-popup="showErrorPopup"
        />
        <!-- </span> -->
      </div>

      <div class="video-section" ref="videosection">
        <div class="video-wrap" :class="{ 'preview-loading': previewLoading }">
          <canvasComp
            v-if="scene && !(previewLoading && !isPreviewImageLoaded)"
            :size="size"
            :frame="frame.current || frame.preview"
            :scene="scene"
          />

          <div
            class="previewImg"
            :class="{ debug: showPreviewImgDebug }"
            v-if="showPreviewImg"
          >
            <img :src="previewImgUrl" alt="" />
          </div>

          <div v-show="previewLoading && !isPreviewImageLoaded" class="preview-wrap">
            <div class="preview" :style="loaderStyle">
              <IdmLoader />
            </div>
          </div>

          <div
            id="idm"
            ref="player"
            :style="playerStyle"
            data-test="scene-page-player"
          ></div>

          <div class="duration" ref="duration" :style="durationStyle"></div>
        </div>

        <imagesProgressBar
          id="imagesProgressBar"
          v-if="scene && preview.sceneDoc && showImagesProgressBar"
          :scene="scene"
          :preview="preview"
          :height="40"
          :frame="frame"
          @changeTime="scrollTime"
          data-test="scene-page-image-progress-bar"
        />
      </div>
    </div>

    <debugBar
      v-if="debugMode"
      :show-preview-img-debug="showPreviewImgDebug"
      :show-images-progress-bar="showImagesProgressBar"
      :debug-log="debugLog"
      @showPreviewImgDebug="showPreviewImgDebug = $event"
      @showImagesProgressBar="showImagesProgressBar = $event"
      :scene="scene"
      :preview="preview"
      :sceneResult="sceneResult"
    ></debugBar>
  </div>
</template>

<script>
import nodeFilter from '@/components/nodeFilter.vue';
import nodesList from '@/components/nodesList.vue';
import debugBar from '@/components/debugBar';
import topBar from '@/views/editor/topbar';
import { Filter, Sort } from '@/helper/consts';
import { useQuery, useResult } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import jsonData from '@/views/editor/homePage';
import { getMiddleTime, getStartTimeFormatted } from '@/helper/time';
import _ from 'lodash';
import frameBinding from '@/helper/frameBinding';
import canvasComp from '../canvas';
import imagesProgressBar from '../imagesProgressBar';
import { onError } from '@apollo/client/link/error';
import { mapMutations, mapActions } from 'vuex';
import editorController from '@/components/editors/index';
import sceneCacheStorage from '@/helper/cache';
import { fileAvailabilityCheck } from '@/helper/fileAvailabilityCheck';

export default {
  name: 'scenePage',

  components: {
    nodeFilter,
    nodesList,
    topBar,
    canvasComp,
    imagesProgressBar,
    editorController,
    debugBar,
  },

  props: ['sceneId'],

  data: () => ({
    isPlaceholdersJsonLoaded: false,
    isFirstAvailabilityChecked: false,

    playerWidth: 0,
    playerSize: {
      width: 0,
      height: 0,
    },
    playerId: 'idm',
    duration: 0,
    frame: {
      current: 0,
      preview: 0,
    },
    currentTime: 0,
    playerOptions: {
      share_button: false,
      src: '',
      interactive: true,
      size: 'hd',
      autoplay: false,
    },

    showCloseScene: false,

    //scene
    sceneResult: null,
    sceneLoading: false,
    sceneError: null,

    //video
    videoResult: null,
    videoLoading: false,
    videoError: null,

    library: {
      result: null,
      loading: false,
      error: null,
    },

    brands: {
      result: null,
      loading: false,
      error: null,
    },

    brandKits: [],

    // scene preview
    isPreviewVideoLoaded: true,
    isPreviewImageLoaded: true,
    preview: {
      requestId: 0,
      result: null,
      loading: false,
      error: null,
      sceneDoc: null,
    },

    filter: Filter.ALL,
    sort: Sort.TIMELINE,

    showPreviewImgDebug: false,
    showImagesProgressBar: false,

    debugLog: [],

    showFontUploadError: false,
    fontUploadErrorData: {},
    firstCacheInit: false,

    // count: 0,
  }),

  watch: {
    selectedNode(newSelectedNode) {
      this.scrollTimeToSelectedNode(newSelectedNode);
    },
    sceneResult(val) {
      //temporary code - to display the last open scenes on the main page (todo)
      const scene = _.get(val, ['getImsScene']);
      if (scene) {
        let scenesInLs = window.localStorage.lastViewedScenes || [];
        if (scenesInLs && scenesInLs.length) {
          scenesInLs = JSON.parse(scenesInLs);
        }
        console.log('scenesInLs', scenesInLs);
        if (!scenesInLs.find((s) => s.id == this.sceneId)) {
          scenesInLs.unshift({
            id: this.sceneId,
            name: scene.scene_name,
          });
          window.localStorage.lastViewedScenes = JSON.stringify(scenesInLs.slice(0, 10));
        }
      }
    },
    previewLoading() {
      this.changePreviewLoading(this.previewLoading);
    },
    previewImgUrl(val) {
      if (val) {
        const initiallyRequestId = this.preview.requestId;
        this.d(`Preview Image url changed`);
        fileAvailabilityCheck(val, { interval: [10, 10, 50, 50, 100, 100, 500] }).then(
          (status) => {
            if (initiallyRequestId != this.preview.requestId) {
              console.log(
                `during the preparation of this preview (${initiallyRequestId}), a new generation request was sent - no further processing is needed [4]`
              );
              return;
            }
            this.d(`Preview Image Loaded ✔️ (status: ${status ? 1 : 0})`);
            this.isPreviewImageLoaded = status;
          }
        );
      }
    },
  },

  computed: {
    cacheExist() {
      return sceneCacheStorage.cacheExist(this.sceneId);
    },
    cachePreviewExist() {
      return sceneCacheStorage.previewExist(this.sceneId);
    },
    libraryId() {
      return this.$route.query.libraryId || null;
    },
    loading() {
      return this.sceneLoading || this.videoLoading || !this.isFirstAvailabilityChecked;
    },
    sceneVideo() {
      return _.get(this.videoResult, ['getSceneVideo'], {});
    },
    sceneVideoUrl() {
      return _.get(
        this.videoResult,
        ['getSceneVideo', 'output', 'video', '0', 'links', 'url'],
        ''
      );
    },
    sceneGifUrl() {
      return _.get(
        this.videoResult,
        ['getSceneVideo', 'output', 'gif', '0', 'links', 'url'],
        ''
      );
    },

    // preview
    previewLoading() {
      return (
        this.preview.loading || !this.isPreviewVideoLoaded || !this.isPreviewImageLoaded
      );
    },
    previewImgUrl() {
      return _.get(
        this.preview.result,
        ['getScenePreview', 'output', 'jpg', '0', 'links', 'url'],
        ''
      );
    },
    previewVideoUrl() {
      return _.get(
        this.preview.result,
        ['getScenePreview', 'output', 'video', '0', 'links', 'url'],
        ''
      );
    },
    previewGifUrl() {
      return _.get(
        this.preview.result,
        ['getScenePreview', 'output', 'gif', '0', 'links', 'url'],
        ''
      );
    },

    showPreviewImg() {
      return (
        (!this.isPreviewVideoLoaded && this.previewImgUrl && this.isPreviewImageLoaded) ||
        this.showPreviewImgDebug
      );
    },

    videoUrl() {
      return this.previewVideoUrl ? this.previewVideoUrl : this.sceneVideoUrl;
    },
    gifUrl() {
      return this.previewGifUrl ? this.previewGifUrl : this.sceneGifUrl;
    },

    originalDuration() {
      return getStartTimeFormatted(this.duration);
    },
    scene() {
      return this.$store.getters['builder/scene'];
    },
    selectedNode() {
      return this.$store.getters['builder/selectedNode'];
    },
    durationWidth() {
      if (!this.duration || !this.selectedNode) return 0;
      if (this.duration <= this.selectedNode.duration_in_seconds) return 100;
      return (this.selectedNode.duration_in_seconds / this.duration) * 100;
    },
    durationLeftPosition() {
      if (!this.duration || !this.selectedNode) return 0;
      return (this.selectedNode.custom_start_time / this.duration) * 100;
    },
    durationStyle() {
      return this.selectedNode
        ? { width: `${this.durationWidth}%`, left: `${this.durationLeftPosition}%` }
        : { display: 'none' };
    },
    debugMode() {
      return this.$store.getters['builder/debugMode'];
    },
    errorResult() {
      return this.preview?.result?.getScenePreview?.errors[0];
    },
    size() {
      return {
        width:
          (this.playerSize.height * (this.scene?.width || 1)) / (this.scene?.height || 1),
        height: this.playerSize.height,
      };
    },
    playerStyle() {
      return {
        opacity: this.preview.loading || !this.isPreviewVideoLoaded ? 0 : 1,
        width: this.playerWidth ? this.playerWidth + 'px' : 'auto',
      };
    },
    loaderStyle() {
      return {
        width: this.playerWidth ? this.playerWidth + 'px' : 'auto',
      };
    },
  },

  methods: {
    ...mapMutations('errors', ['setError', 'handleErrorStatus']),
    ...mapActions('builder', [
      'standardization',
      'selectNode',
      'setLibraries',
      'setBrands',
      'setDebugMode',
      'changePreviewLoading',
    ]),
    closeSceneEvent() {
      if (this.scene && this.scene.edited) {
        this.showCloseScene = true;
        return;
      }

      this.leaveScene();
    },
    leaveScene() {
      this.$router.push({ name: 'editor_home' });
    },
    getSceneDoc() {
      function removeCustomProps(node) {
        return _.omit(node, [
          'placeholder',
          'media_type',
          'name',
          'edited',
          'renamed',
          'color',
          'fileName',
          'mediaLink',
        ]);
      }

      const media = _.filter(this.scene.nodes, (node) => {
        return (
          node.media_type === 'color' ||
          node.media_type === 'image' ||
          node.media_type === 'video'
        );
      });
      const text = _.filter(this.scene.nodes, { media_type: 'text' });
      const audio = _.filter(this.scene.nodes, { media_type: 'audio' });

      return {
        media: _.map(media, removeCustomProps),
        text: _.map(text, removeCustomProps),
        audio: _.map(audio, removeCustomProps),
      };
    },
    previewModifiedScene(forcePreview = false) {
      this.preview.requestId++;
      this.d('clear');
      this.d(`previewModifiedScene ${this.preview.requestId} start`);
      console.log(`previewModifiedScene ${this.preview.requestId} start`);

      this.isPreviewVideoLoaded = false;
      this.isPreviewImageLoaded = false;

      const player = window[this.playerId];
      const durationInSeconds = this.scene.duration.seconds;
      let timePoint = player.currentTime() || this.currentTime;
      if (timePoint > durationInSeconds) {
        timePoint = _.floor(durationInSeconds, 2);
        player.currentTime(timePoint);
        this.currentTime = timePoint;
      }

      this.preview.sceneDoc = this.getSceneDoc();
      console.log('sceneDoc', this.preview.sceneDoc);
      this.getScenePreview(this.scene.id, timePoint, this.preview.sceneDoc);
      // this.count++;
    },
    showErrorPopup(data) {
      this.fontUploadErrorData = data;
      this.showFontUploadError = true;
    },
    scrollTimeToSelectedNode(node) {
      if (!node || (node.is_hidden && !node.is_trackmatte)) {
        return;
      }

      let player = window[this.playerId];
      if (player) {
        player.pause();
        let middleTime = getMiddleTime(node.custom_start_time, node.duration_in_seconds);
        player.currentTime(middleTime);
      }
    },
    scrollTime(time) {
      console.log('scrollTime', time);
      let player = window[this.playerId];
      if (player) {
        player.currentTime(time);
      }
    },
    initVideoPlayer() {
      if (this.sceneLoading || this.videoLoading) {
        return;
      }

      let player = window[this.playerId];

      if (typeof player?.dispose === 'function') {
        player.dispose();
      }

      this.d(`[initVideoPlayer] video check started`);

      fileAvailabilityCheck(this.sceneVideoUrl, { interval: [10, 100, 500, 1000] }).then(
        (status) => {
          this.d(
            `[initVideoPlayer] video availability check complete. status: ${
              status ? 1 : 0
            }`
          );
          this.isFirstAvailabilityChecked = true;

          this.playerOptions.src = this.sceneVideoUrl;
          idmPlayerCreate(this.playerOptions, this.playerId);

          const playerObj = window[this.playerId];
          playerObj.ready(() => {
            playerObj.on('timeupdate', () => {
              if (
                !this.isPreviewVideoLoaded &&
                playerObj.currentTime() === this.currentTime &&
                this.previewVideoUrl == playerObj.src()
              ) {
                this.d(`player timeupdate - Preview Video Loaded ✔️✔️✔️`);
                this.isPreviewImageLoaded = true; //if the video has already, the image will not be shown
                this.isPreviewVideoLoaded = true;
              }
            });
            playerObj.on('loadedmetadata', () => {
              this.duration = playerObj.duration();
              this.onResize();
              if (!this.isPreviewVideoLoaded && this.previewVideoUrl == playerObj.src()) {
                this.d(`player loadedmetadata - currentTime changed`);
                playerObj.currentTime(this.currentTime);
              }

              this.onVideoSectionResize();
            });
            playerObj.on('loadedplaceholders', () => {
              const progressHolder = document.querySelector('.vjs-progress-holder');
              progressHolder.appendChild(this.$refs.duration);

              const scene = _.get(this.sceneResult, ['getImsScene']);
              const placeholders = playerObj.setup.placeholders?.list;
              const frames = playerObj.setup.placeholders?.positions?.frames;

              this.standardization({ scene, placeholders, frames });
              this.preview.sceneDoc = this.getSceneDoc();
              this.isPlaceholdersJsonLoaded = true;
              if (!this.firstCacheInit && this.cacheExist && this.cachePreviewExist) {
                this.firstCacheInit = true;
                this.previewModifiedScene(true);
              }
              this.frame.current = frameBinding(this.playerId, this.scene.fps);
            });
          });
        }
      );
    },
    getScene() {
      const { result, loading, error, onResult, onError, loaded } = useQuery(
        gql`
          query getScene($id: ID!) {
            getImsScene(id: $id)
          }
        `,
        () => ({ id: this.sceneId }),
        { fetchPolicy: 'no-cache' }
      );

      onResult((res) => {
        this.handleErrorStatus({ requestSlug: 'getScene', response: res });
        if (res?.data?.getImsScene != null) {
          this.initVideoPlayer();
        }
      });

      onError((e) => {
        const err = error.value.graphQLErrors[0] ?? e;
        this.setError({
          error_code: err.extensions?.code,
          error_description: err.message,
        });
      });

      this.sceneResult = result;
      this.sceneLoading = loading;
      this.sceneError = error;
    },

    getSceneVideo() {
      if (this.cacheExist && this.cachePreviewExist) {
        this.firstCacheInit = true;
        this.videoResult = {
          getSceneVideo: sceneCacheStorage.getScenePreview(this.sceneId),
        };
        this.initVideoPlayer();
        return;
      }

      const { result, loading, error, onResult, onError } = useQuery(
        gql`
          query getSceneVideo($id: ID!) {
            getSceneVideo(id: $id)
          }
        `,
        () => ({ id: this.sceneId }),
        { fetchPolicy: 'no-cache' }
      );

      onResult((res) => {
        console.log('video**********************');
        this.handleErrorStatus({ requestSlug: 'getSceneVideo', response: res });
        this.initVideoPlayer();
      });
      onError((e) => {
        const err = error.value.graphQLErrors[0] ?? e;
        this.setError({
          error_code: err.extensions?.code,
          error_description: err.message,
        });
      });

      this.videoResult = result;
      this.videoLoading = loading;
      this.videoError = error;
    },

    getSceneLibraries() {
      const { result, loading, error, onResult } = useQuery(
        gql`
          query getSceneLibraries {
            getSceneLibraries
          }
        `,
        { fetchPolicy: 'no-cache' }
      );

      onResult((res) => {
        this.handleErrorStatus({ requestSlug: 'getSceneLibraries', response: res });
        this.setLibraries(_.get(this.library.result, ['getSceneLibraries'], []));
      });

      this.library.result = result;
      this.library.loading = loading;
      this.library.error = error;
    },

    getBrandKits() {
      const { result, loading, error, onResult } = useQuery(
        gql`
          query getBrandKits {
            getBrandKits {
              success
              message
              kits
            }
          }
        `,
        { fetchPolicy: 'no-cache' }
      );

      onResult((res) => {
        if (res.data.getBrandKits?.status === 'Error') {
          this.handleErrorStatus({ requestSlug: 'getBrandKits', response: res });
        } else {
          const kits = _.get(res, ['data', 'getBrandKits', 'kits'], []);
          this.setBrands(kits);
        }
      });
    },

    getScenePreview(sceneId, timePoint, sceneDoc) {
      const initiallyRequestId = this.preview.requestId;
      console.log(`getScenePreview. initiallyRequestId:${initiallyRequestId}`);
      console.log('*******************************');
      console.log('sceneId: ', sceneId);
      console.log('timePoint: ', timePoint);
      console.log('sceneDoc: ', sceneDoc);
      console.log('*******************************');
      const { result, loading, error, onResult } = useQuery(
        gql`
          query GetScenePreview($id: ID!, $scene_doc: JSON!, $time_point: Float) {
            getScenePreview(id: $id, scene_doc: $scene_doc, time_point: $time_point)
          }
        `,
        () => ({
          id: sceneId,
          time_point: timePoint,
          scene_doc: sceneDoc,
        }),
        { fetchPolicy: 'no-cache' }
      );

      onResult((res) => {
        if (initiallyRequestId != this.preview.requestId) {
          console.log(
            `during the preparation of this preview (${initiallyRequestId}), a new generation request was sent - no further processing is needed [1]`
          );
          return;
        }

        if (res.data.getScenePreview?.status === 'Error') {
          this.handleErrorStatus({ requestSlug: 'getScenePreview', response: res });
        } else {
          sceneCacheStorage.updateSceneCache(this.scene, res.data.getScenePreview);
        }

        const src = this.previewVideoUrl;
        console.log('src: ', src);
        this.d(`availability video check start`);

        fileAvailabilityCheck(src, { interval: [10, 100, 500, 1000] }).then((status) => {
          if (initiallyRequestId != this.preview.requestId) {
            console.log(
              `during the preparation of this preview (${initiallyRequestId}), a new generation request was sent - no further processing is needed [3]`
            );
            return;
          }
          this.d(`video availability check complete. status: ${status ? 1 : 0}`);
          console.log('33333');
          this.showPreviewVideo(); // continue regardless of status
        });
      });

      onError(() => {
        if (initiallyRequestId != this.preview.requestId) {
          console.log(
            `during the preparation of this preview (${initiallyRequestId}), a new generation request was sent - no further processing is needed [2]`
          );
          return;
        }

        const err = error.value.graphQLErrors[0] ?? e;
        this.setError({
          error_code: err.extensions?.code,
          error_description: err.message,
        });
        this.isPreviewVideoLoaded = true;
      });

      this.preview.result = result;
      this.preview.loading = loading;
      this.preview.error = error;
    },

    showPreviewVideo() {
      const player = window[this.playerId];
      this.currentTime = player.currentTime() || this.currentTime; //for test - whatever the position is not reset to zero
      console.log(`new currentTime: ${this.currentTime}`);
      this.frame.preview = this.frame.current;
      this.d(`player video and poster changed`);

      player.src({ src: this.previewVideoUrl });
      player.poster(this.previewImgUrl);
    },

    onResize() {
      this.playerSize.height = this.$refs.player.offsetHeight;
      this.playerSize.width = this.$refs.player.offsetWidth;
    },

    onVideoSectionResize() {
      const playerHeight = this.$refs.player.offsetHeight;
      const playerWidth = this.$refs.player.offsetWidth;
      const computedStyle = getComputedStyle(this.$refs.videosection);
      const elementHeight =
        this.$refs.videosection.clientHeight -
        (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom));

      if (playerHeight + 40 > elementHeight || playerHeight + 40 < elementHeight) {
        this.playerWidth = (elementHeight - 40) * (playerWidth / playerHeight);
      }
    },

    d(message) {
      if (message == 'clear') {
        this.debugLog = [];
      } else {
        this.debugLog.push([new Date().getTime(), message]);
      }
    },
  },

  created() {
    this.getScene();
    this.getSceneVideo();
    this.getSceneLibraries();
    this.getBrandKits();
  },

  mounted() {
    this.ro = new ResizeObserver(this.onResize);
    this.ro.observe(this.$refs.player);

    this.roVideoSection = new ResizeObserver(this.onVideoSectionResize);
    this.roVideoSection.observe(this.$refs.videosection);

    this.developDebug = (event) => {
      if (event.code === 'KeyD' && event.shiftKey) {
        this.setDebugMode(!this.debugMode);
      }
    };
    if (process.env.NODE_ENV !== 'production') {
      document.addEventListener('keydown', this.developDebug);
    }
  },

  beforeUnmount() {
    if (process.env.NODE_ENV !== 'production') {
      document.removeEventListener('keydown', this.developDebug);
    }
  },
};
</script>

<style lang="scss">
.popup-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .idm-popup-container {
    width: 416px;
    background-color: #fff;
    z-index: 1;

    .text-container p {
      margin-bottom: 0;

      &:nth-child(2) {
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }

  &.popup-error {
    .idm-popup-container .text-container p {
      a {
        font-weight: 400;
        color: #377fff;
      }
      &:nth-child(2) {
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #305768;
    opacity: 0.5;
    z-index: 0;
  }
}

.video-wrap.preview-loading {
  .vjs-control-bar {
    pointer-events: none;
  }
}
</style>

<style lang="scss" scoped>
.video-wrap {
  position: relative;

  [id='idm'] {
    max-width: 100% !important;
  }

  .preview-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
  }

  .preview {
    max-width: 100% !important;
    height: 100%;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /*overflow: hidden;*/

    .img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background-color: black;
      opacity: 0.7;
    }
  }
}

.previewImg {
  position: absolute;
  z-index: 9;
  inset: 0;
  display: flex;
  justify-content: center;

  &.debug {
    opacity: 0.5;
    filter: invert(1);
  }

  img {
    height: 100%;
  }
}
#imagesProgressBar {
  margin-top: 70px;
}
</style>

import moment from 'moment';

const timeFormat = 'mm:ss.SS'

export const getStartTimeFormatted = (startTime) => {
    let durationObj = moment.duration({seconds: startTime,})

    return moment.utc(durationObj.asMilliseconds()).format(timeFormat)
}

export const getEndTimeFormatted = (startTime, duration) => {
    let durationObj = moment.duration({seconds: startTime,})
    durationObj.add(duration * 1000, 'ms')

    return moment.utc(durationObj.asMilliseconds()).format(timeFormat)
}

export const getMiddleTime = (startTime, duration) => {
    let durationObj = moment.duration({seconds: startTime,})
    durationObj.add((duration * 1000) / 2, 'ms')

    return durationObj.asSeconds()
}